import React, { useEffect } from "react";
import { Layout, Breadcrumb, Button } from "antd";
import * as S from "./Organizations.styled";
import {
  OrganizationsListItemsSelector,
  fetchOrganizations,
} from "features/OrganizationsList/OrganizationsListSlice";
import { useAppDispatch } from "hooks/useStore";
import OrganizationsList from "features/OrganizationsList";
import { useSelector } from "react-redux";
import Meta from "components/Meta";
import { AcceptedSelector, UserIdSelector } from "features/User/UserSlice";
import { openCreateOrganizationModal } from "features/CreateOrganizationModal/CreateOrganizationModalSlice";

const { Content } = Layout;

const Organizations: React.FC = () => {
  const dispatch = useAppDispatch();

  // Список загруженных сервисных. центров
  const services = useSelector(OrganizationsListItemsSelector);
  const userId = useSelector(UserIdSelector);

  // Подтвержденность пользователя
  const isAccepted = useSelector(AcceptedSelector);

  useEffect(() => {
    dispatch(fetchOrganizations(userId));
  }, []);

  // Открытие модалки
  const onOpenNewOrgModal = () =>
    dispatch(openCreateOrganizationModal({ userId }));

  return (
    <>
      <Meta pageName="services" />
      <Content
        style={{
          padding: "0px 20px 0px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumb
          items={[
            { title: "managment platform" },
            { title: `all technical services (${services.length})` },
          ]}
          style={{ margin: "16px 0" }}
        />
        <S.WorkSpace>
          <S.Header>
            <Button
              type="primary"
              size="large"
              onClick={onOpenNewOrgModal}
              block
              disabled={!isAccepted}
            >
              Add technical center
            </Button>
          </S.Header>
          <OrganizationsList />
        </S.WorkSpace>
      </Content>
    </>
  );
};

export default Organizations;
