import React from "react";
import { Avatar, Button, Typography } from "antd";
import * as S from "./User.styled";
import { IUserProps } from "../interfaces";
import { UserOutlined } from "@ant-design/icons";

const { Text } = Typography;

const User: React.FC<IUserProps> = ({
  name,
  email,
  photo,
  onRemove,
}: IUserProps) => {
  return (
    <S.User>
      <Avatar size={44} src={photo} icon={!photo ? <UserOutlined /> : null} />
      <S.Info>
        <S.Name>
          <Text>{name}</Text>
        </S.Name>
        <S.Email>
          <Text type="secondary">{email}</Text>
        </S.Email>
      </S.Info>
      <S.Remove>
        <Button size="small" onClick={onRemove} type="link">
          remove
        </Button>
      </S.Remove>
    </S.User>
  );
};

export default User;
