import styled from "styled-components";

export const User = styled.div`
  display: flex;
`;

export const Info = styled.div`
  margin-left: 12px;
  flex-grow: 1;
`;

export const Name = styled.div``;

export const Email = styled.div``;

export const Remove = styled.div`
  display: flex;
  align-items: center;
`;
