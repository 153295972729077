import React from "react";
import * as S from "./RecordsList.styled";
import { useSelector } from "react-redux";
import { removeRecord, RecordsListItemsSelector } from "./RecordsListSlice";
import Table from "./components/Table";
import { useAppDispatch } from "hooks/useStore";
import { openEditRecordModal } from "features/EditRecordModal/EditRecordModalSlice";
import {
  BrandsSelector,
  StatusSelector,
} from "features/Libraries/LibrariesSlice";
import { AcceptedSelector } from "features/User/UserSlice";

const RecordsList: React.FC = () => {
  const dispatch = useAppDispatch();

  // Список загруженных сервисных. центров
  const records = useSelector(RecordsListItemsSelector);

  // Подтвержденность пользователя
  const isAccepted = useSelector(AcceptedSelector);

  // Все вожможные статусы
  const allStatuses = useSelector(StatusSelector);

  // Все вожможные бренды автомобилей
  const allBrands = useSelector(BrandsSelector);

  // Открытие модалки с карточкой сервис. сентра для редактирования
  const onOpenEditRecordModal = (recordId: number) =>
    dispatch(openEditRecordModal(recordId));

  // Удаление сервисного центра
  const onRemoveService = (id: number) => dispatch(removeRecord(id));

  return (
    <S.Wrap>
      <Table
        items={records}
        isDisabled={!isAccepted}
        allStatuses={allStatuses}
        allBrands={allBrands}
        onEdit={onOpenEditRecordModal}
        onRemove={onRemoveService}
      />
    </S.Wrap>
  );
};

export default RecordsList;
