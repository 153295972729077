import React, { useState } from "react";
import * as S from "./Table.styled";
import { Table as TableAnt, Button, Tag, Popconfirm } from "antd";
import { ITableProps, ITableSort } from "features/RecordsList/interfaces";
import dayjs from "dayjs";
import {
  IBrand,
  ICar,
  IRecord,
  IService,
  IStatus,
  IUser,
} from "interfaces/main";

const Table: React.FC<ITableProps> = ({
  items,
  isDisabled,
  allStatuses,
  allBrands,
  onEdit,
  onRemove,
}) => {
  // Сортировка в табличке
  const [sort, setSort] = useState<ITableSort>({
    order: null,
    columnKey: null,
  });

  // Конфиг таблички для Ant Table
  const columns = [
    {
      title: "Brand",
      dataIndex: "car",
      key: "brand",
      sortOrder: sort.columnKey === "brand" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a?.car?.brandId < b?.car?.brandId) return -1;
          if (a?.car?.brandId > b?.car?.brandId) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (car: ICar) =>
        allBrands.find((item: IBrand) => item.id === car?.brandId)?.title || "",
    },
    {
      title: "Model",
      dataIndex: "car",
      key: "model",
      sortOrder: sort.columnKey === "model" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a?.car?.model < b?.car?.model) return -1;
          if (a?.car?.model > b?.car?.model) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (car: ICar) => car?.model || "",
    },
    {
      title: "Year",
      dataIndex: "car",
      key: "year",
      sortOrder: sort.columnKey === "year" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a?.car?.year < b?.car?.year) return -1;
          if (a?.car?.year > b?.car?.year) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (car: ICar) => car?.year || "",
    },
    {
      title: "VIN",
      dataIndex: "car",
      key: "vin",
      sortOrder: sort.columnKey === "carVin" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a?.car?.vin < b?.car?.vin) return -1;
          if (a?.car?.vin > b?.car?.vin) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (car: ICar) => car?.vin || "",
    },
    {
      title: "Name",
      dataIndex: "user",
      key: "client",
      sortOrder: sort.columnKey === "client" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a?.user?.name < b?.user?.name) return -1;
          if (a?.user?.name > b?.user?.name) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (user: IUser) => user?.name || "",
    },
    {
      title: "Phone",
      dataIndex: "user",
      key: "phone",
      sortOrder: sort.columnKey === "phone" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a?.user?.phone < b?.user?.phone) return -1;
          if (a?.user?.phone > b?.user?.phone) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (user: IUser) => user?.phone || "",
    },
    {
      title: "Client's comment",
      dataIndex: "clientComment",
      key: "clientComment",
      sortOrder: sort.columnKey === "clientComment" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.clientComment < b.clientComment) return -1;
          if (a.clientComment > b.clientComment) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sortOrder: sort.columnKey === "meetDate" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.date < b.date) return -1;
          if (a.date > b.date) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (date: string) => dayjs(date).format("DD.MM.YYYY"),
    },
    {
      title: "Organization's comment",
      dataIndex: "organizationComment",
      key: "organizationComment",
      sortOrder: sort.columnKey === "organizationComment" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.organizationComment < b.organizationComment) return -1;
          if (a.organizationComment > b.organizationComment) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Status",
      dataIndex: "statusId",
      key: "statusId",
      sortOrder: sort.columnKey === "statusId" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a?.statusId < b?.statusId) return -1;
          if (a?.statusId > b?.statusId) return 1;
          return 0;
        },
        multiple: 1,
      },
      render: (statusId) => {
        const myStatus = allStatuses.find(
          (item: IStatus) => item.id === statusId
        );

        return (
          <Tag color={myStatus.antColor} key={myStatus.id}>
            {myStatus.name}
          </Tag>
        );
      },
    },
    {
      title: "Services",
      dataIndex: "services",
      key: "services",
      sortOrder: sort.columnKey === "services" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IRecord, b: IRecord) => {
          if (a.services.length < b.services.length) return -1;
          if (a.services.length > b.services.length) return 1;
          return 0;
        },
        multiple: 6,
      },
      render: (services: IService[]) =>
        services.length ? (
          <S.Services>
            {services.map((item: IService) => (
              <Tag key={item.id}>{item.title}</Tag>
            ))}
          </S.Services>
        ) : (
          "-"
        ),
    },
    {
      title: "",
      key: "action",
      render: (_: string, record: IRecord) => {
        return (
          <S.Actions>
            <Button
              onClick={() => onEdit(record.id)}
              disabled={isDisabled}
              size="small"
            >
              edit
            </Button>
            <Popconfirm
              placement="rightBottom"
              title="Are you sure to delete this request?"
              onConfirm={() => onRemove(record.id)}
              okText="delete"
              cancelText="cancel"
              disabled={isDisabled}
            >
              <Button disabled={isDisabled} size="small">
                remove
              </Button>
            </Popconfirm>
          </S.Actions>
        );
      },
    },
  ];

  // Изменение сортировки и фильтрации в табличке
  const onChange = (_: any, filters: any, sorter: any) => {
    setSort(sorter);
  };

  return (
    <S.Wrap>
      <TableAnt
        columns={columns}
        dataSource={items.map((rec: IRecord) => ({
          key: rec.id,
          ...rec,
        }))}
        onChange={onChange}
      />
    </S.Wrap>
  );
};

export default Table;
