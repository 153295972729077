import styled from "styled-components";
import background from "../../assets/images/dubai-road.jpg";

export const Login = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${background});
  background-size: cover;

  .form {
    background-color: #fff;
    padding: 40px;
  }
`;

export const Form = styled.div`
  width: 400px;
`;

export const Head = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const Policy = styled.div`
  text-align: center;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.45);
`;
