import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { IEditRecordModalState } from "./interfaces";
import { ICar, IRecord, IService, IUser } from "interfaces/main";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "api/requests";

// Открытие окна с существующей записью
export const openEditRecordModal = createAction(
  "editRecordModal/openEditRecordModal",
  (recordId: number) => ({ payload: recordId })
);

// Ручная установка значения поля
export const setField = createAction(
  "editRecordModal/setField",
  ({ key, value }: { key: string; value: IUser | ICar }) => ({
    payload: { key, value },
  })
);

// Открытие окна с новой целью
export const closeModal = createAction("editRecordModal/closeModal");

// Загрузка всей записи по ID
export const getRecordById = createAsyncThunk(
  "editRecordModal/getRecordById",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await getRequest(`record/${id}`, {}, true);
      const recData = response.data;
      const record: IRecord = {
        id: recData?.record?.id || null,
        clientComment: recData?.record?.clientComment || "",
        organizationComment: recData?.record?.organizationComment || "",
        createdAt: recData?.record?.createdAt || "",
        updatedAt: recData?.record?.updatedAt || "",
        date: recData?.record?.date || "",
        carId: recData?.record?.carId || null,
        userId: recData?.record?.userId || null,
        organizationId: recData?.record?.organizationId || null,
        statusId: recData?.record?.statusId || null,
        services: recData?.services || [],
        car: recData?.car || null,
        user: recData?.user || null,
      };
      return record;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Поиск услуг по запросу и organizationId
export const findServiceByName = createAsyncThunk(
  "editRecordModal/findServiceByName",
  async (
    { query, organizationId }: { query: string; organizationId: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await getRequest(
        `service?organizationId=${organizationId}&query=${query}`
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Установка значения записи
export const changeRecord = createAsyncThunk(
  "editRecordModal/changeRecord",
  async (
    {
      key,
      recordId,
      value,
    }: {
      key: string;
      recordId: number;
      value: string | number | boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await putRequest(
        `record/${recordId}`,
        {
          [key]: value,
        },
        true
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Добавление услуги
export const addService = createAsyncThunk(
  "editRecordModal/addService",
  async (
    { recordId, service }: { recordId: number; service: IService },
    { rejectWithValue }
  ) => {
    try {
      await postRequest(
        "recordService",
        { recordId, serviceId: service.id },
        true
      );

      return { service };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление услуги
export const removeService = createAsyncThunk(
  "editRecordModal/removeService",
  async (recordServiceId: number, { rejectWithValue }) => {
    try {
      const response = await deleteRequest(
        `recordService/${recordServiceId}`,
        {},
        true
      );

      return { id: response.data.id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Поиск автомобилей конкретного пользователя
export const getCarsByUserId = createAsyncThunk(
  "editRecordModal/getCarsByUserId",
  async ({ userId }: { userId: number }, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(`car?userId=${userId}`);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialRecord: IRecord = {
  id: null,
  date: "",
  clientComment: "",
  organizationComment: "",
  createdAt: "",
  updatedAt: "",
  organizationId: null,
  carId: null,
  statusId: null,
  userId: null,
  services: [],
};

const initialState: IEditRecordModalState = {
  data: initialRecord,
  searchedServices: [],
  searchedCars: [],
  isVisible: false,
  isLoading: false,
  isLoadingServices: false,
  isLoadingCars: false,
};

const editRecordModalSlice = createSlice({
  name: "editRecordModal",
  initialState,
  reducers: {
    openEditRecordModal: (_, { payload }) => ({
      ...initialState,
      isVisible: true,
      data: {
        ...initialRecord,
        id: payload,
      },
    }),
    closeModal: () => initialState,
    setField: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        [payload.key]: payload.value,
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecordById.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getRecordById.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(getRecordById.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ...payload,
        },
      }))
      .addCase(changeRecord.fulfilled, (state, { payload }) => ({
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      }))
      .addCase(findServiceByName.pending, (state) => ({
        ...state,
        isLoadingServices: true,
      }))
      .addCase(findServiceByName.rejected, (state) => ({
        ...state,
        isLoadingServices: false,
      }))
      .addCase(findServiceByName.fulfilled, (state, { payload }) => ({
        ...state,
        isLoadingServices: false,
        searchedServices: payload,
      }))
      .addCase(getCarsByUserId.pending, (state) => ({
        ...state,
        isLoadingCars: true,
      }))
      .addCase(getCarsByUserId.rejected, (state) => ({
        ...state,
        isLoadingCars: false,
      }))
      .addCase(getCarsByUserId.fulfilled, (state, { payload }) => ({
        ...state,
        isLoadingCars: false,
        searchedCars: payload,
      }))
      .addCase(addService.fulfilled, (state, { payload }) => ({
        ...state,
        data: {
          ...state.data,
          services: [...state.data.services, payload.service],
        },
      }))
      .addCase(removeService.fulfilled, (state, { payload }) => ({
        ...state,
        data: {
          ...state.data,
          services: state.data.services.filter(
            (item: IService) => item.id !== payload.id
          ),
        },
      }));
  },
});

// Селекторы
export const EditRecordModalSelector = (
  state: RootState
): IEditRecordModalState => state.editRecordModal;

export const EditRecordModalLoadingSelector = createSelector(
  EditRecordModalSelector,
  ({ isLoading }) => isLoading
);

export const EditRecordModalVisibleSelector = createSelector(
  EditRecordModalSelector,
  ({ isVisible }) => isVisible
);

export const EditRecordModalDataSelector = createSelector(
  EditRecordModalSelector,
  ({ data }) => data
);

export const ServicesLoadingSelector = createSelector(
  EditRecordModalSelector,
  ({ isLoadingServices }) => isLoadingServices
);

export const CarsLoadingSelector = createSelector(
  EditRecordModalSelector,
  ({ isLoadingCars }) => isLoadingCars
);

export const SearchedServicesSelector = createSelector(
  EditRecordModalSelector,
  ({ searchedServices }) => searchedServices
);

export const SearchedCarsSelector = createSelector(
  EditRecordModalSelector,
  ({ searchedCars }) => searchedCars
);

export default editRecordModalSlice.reducer;
