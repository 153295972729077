import { combineReducers } from "@reduxjs/toolkit";
import createOrganizationModalSlice from "../features/CreateOrganizationModal/CreateOrganizationModalSlice";
import editOrganizationModalSlice from "../features/EditOrganizationModal/EditOrganizationModalSlice";
import userModalSlice from "../features/UserModal/UserModalSlice";
import organizationsListSlice from "../features/OrganizationsList/OrganizationsListSlice";
import messagesListSlice from "../features/MessagesList/MessagesListSlice";
import logsListSlice from "../features/LogsList/LogsListSlice";
import editRecordModalSlice from "../features/EditRecordModal/EditRecordModalSlice";
import recordsListSlice from "../features/RecordsList/RecordsListSlice";
import librariesSlice from "../features/Libraries/LibrariesSlice";
import userSlice from "../features/User/UserSlice";
import usersListSlice from "../features/UsersList/UsersListSlice";

const rootReducer = combineReducers({
  createOrganizationModal: createOrganizationModalSlice,
  editOrganizationModal: editOrganizationModalSlice,
  organizationsList: organizationsListSlice,
  editRecordModal: editRecordModalSlice,
  recordsList: recordsListSlice,
  messagesList: messagesListSlice,
  logsList: logsListSlice,
  libraries: librariesSlice,
  user: userSlice,
  usersList: usersListSlice,
  userModal: userModalSlice,
});

export default rootReducer;
