import { IOrganization } from "interfaces/main";

export const mapOrganization = (
  organization: IOrganization
): IOrganization => ({
  id: organization.id,
  title: organization.title,
  description: organization.description,
  email: organization.email,
  phone: organization.phone,
  site: organization.site,
  inn: organization.inn,
  longitude: organization.longitude,
  latitude: organization.latitude,
  boxesCount: organization.boxesCount,
  createdAt: organization.createdAt,
  updatedAt: organization.updatedAt,
  userId: organization.userId,
  categories: organization?.categories || [],
  intervals: organization?.intervals || [],
  records: organization?.records || [],
  logotype: organization?.logotype || null,
  brands: organization?.brands || [],
  likes: organization?.likes || [],
  user: organization?.user || null,
  photos: organization?.photos || [],
  reviews: organization?.reviews || [],
  marks: organization?.marks || [],
  services: organization?.services || [],
  tags: organization?.tags || [],
});
