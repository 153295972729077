export const fieldName = {
  tag: {
    single: "tag",
    multiple: "tags",
    foreignKey: "tagId",
    capitalLetter: "Tag",
  },
  category: {
    single: "category",
    multiple: "categories",
    foreignKey: "categoryId",
    capitalLetter: "Category",
  },
  brand: {
    single: "brand",
    multiple: "brands",
    foreignKey: "brandId",
    capitalLetter: "Brand",
  },
  mark: {
    single: "mark",
    multiple: "marks",
    foreignKey: "markId",
    capitalLetter: "Mark",
  },
  service: {
    single: "service",
    multiple: "services",
    foreignKey: "serviceId",
    capitalLetter: "Service",
  },
  review: {
    single: "review",
    multiple: "reviews",
    foreignKey: "reviewId",
    capitalLetter: "Review",
  },
  interval: {
    single: "interval",
    multiple: "intervals",
    foreignKey: "intervalId",
    capitalLetter: "Interval",
  },
  like: {
    single: "like",
    multiple: "likes",
    foreignKey: "likeId",
    capitalLetter: "Like",
  },
};
