import styled from "styled-components";

export const Period = styled.div`
  margin-bottom: 6px;
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;

  p {
    margin: 0px;
  }
`;
