import React from "react";
import { Menu } from "antd";
import { StarOutlined, MailOutlined } from "@ant-design/icons";
import { NavLink, useLocation } from "react-router-dom";
import * as S from "./Navbar.styled";

export const menuItems = [
  {
    key: "/",
    icon: <StarOutlined />,
    label: <NavLink to="/">Organizations</NavLink>,
  },
  {
    key: "/records",
    icon: <MailOutlined />,
    label: <NavLink to="/records">Records</NavLink>,
  },
];

const Navbar: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <S.Navbar>
      <Menu selectedKeys={[pathname]} mode="horizontal" items={menuItems} />
    </S.Navbar>
  );
};

export default Navbar;
