import styled from "styled-components";

export const Line = styled.div`
  display: flex;
  width: "100%";
  gap: 16px;
`;

export const Period = styled.div`
  margin-bottom: 6px;
`;

export const Service = styled.div`
  margin-bottom: 6px;
`;

export const Images = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Image = styled.div`
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
`;

export const UnloadedImages = styled.ul`
  opacity: 0.5;
`;

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.9);
`;
