import React, { useState } from "react";
import * as S from "./Table.styled";
import { ITableProps, ITableSort } from "features/OrganizationsList/interfaces";
import { Table as TableAnt, Button, Popconfirm } from "antd";
import { IOrganization } from "interfaces/main";

const Table: React.FC<ITableProps> = ({
  items,
  isDisabled,
  onEdit,
  onRemove,
}) => {
  // Сортировка в табличке
  const [sort, setSort] = useState<ITableSort>({
    order: null,
    columnKey: null,
  });

  // Конфиг таблички для Ant Table
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sortOrder: sort.columnKey === "title" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IOrganization, b: IOrganization) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Address",
      dataIndex: "description",
      key: "description",
      sortOrder: sort.columnKey === "description" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IOrganization, b: IOrganization) => {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
          return 0;
        },
        multiple: 2,
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sortOrder: sort.columnKey === "phone" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IOrganization, b: IOrganization) => {
          if (a.phone < b.phone) return -1;
          if (a.phone > b.phone) return 1;
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      sortOrder: sort.columnKey === "site" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IOrganization, b: IOrganization) => {
          if (a.site < b.site) return -1;
          if (a.site > b.site) return 1;
          return 0;
        },
        multiple: 3,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sortOrder: sort.columnKey === "email" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IOrganization, b: IOrganization) => {
          if (a.email < b.email) return -1;
          if (a.email > b.email) return 1;
          return 0;
        },
        multiple: 5,
      },
    },
    {
      title: "INN",
      dataIndex: "inn",
      key: "inn",
      sortOrder: sort.columnKey === "inn" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IOrganization, b: IOrganization) => {
          if (a.inn < b.inn) return -1;
          if (a.inn > b.inn) return 1;
          return 0;
        },
        multiple: 5,
      },
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
      sortOrder: sort.columnKey === "latitude" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IOrganization, b: IOrganization) => {
          if (a.latitude < b.latitude) return -1;
          if (a.latitude > b.latitude) return 1;
          return 0;
        },
        multiple: 6,
      },
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
      sortOrder: sort.columnKey === "longitude" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IOrganization, b: IOrganization) => {
          if (a.longitude < b.longitude) return -1;
          if (a.longitude > b.longitude) return 1;
          return 0;
        },
        multiple: 6,
      },
    },
    {
      title: "Categories",
      dataIndex: "categories",
      key: "categories",
      sortOrder: sort.columnKey === "categories" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IOrganization, b: IOrganization) => {
          if (a.categories.length < b.categories.length) return -1;
          if (a.categories.length > b.categories.length) return 1;
          return 0;
        },
        multiple: 3,
      },
      render: (_: string, organization: IOrganization) =>
        organization.categories?.length || 0,
    },
    {
      title: "Photo",
      dataIndex: "photos",
      key: "photos",
      sortOrder: sort.columnKey === "photos" && sort.order,
      ellipsis: true,
      sorter: {
        compare: (a: IOrganization, b: IOrganization) => {
          if (a.photos.length < b.photos.length) return -1;
          if (a.photos.length > b.photos.length) return 1;
          return 0;
        },
        multiple: 3,
      },
      render: (_: string, organization: IOrganization) =>
        organization.photos?.length || 0,
    },
    {
      title: "",
      key: "action",
      render: (_: string, organization: IOrganization) => {
        return (
          <S.Actions>
            <Button
              onClick={() => onEdit(organization.id)}
              size="small"
              disabled={isDisabled}
            >
              edit
            </Button>
            <Popconfirm
              placement="rightBottom"
              title="Are you sure to delete this organization?"
              onConfirm={() => onRemove(organization.id)}
              okText="delete"
              cancelText="cancel"
              disabled={isDisabled}
            >
              <Button size="small" disabled={isDisabled}>
                delete
              </Button>
            </Popconfirm>
          </S.Actions>
        );
      },
    },
  ];

  // Изменение сортировки и фильтрации в табличке
  const onChange = (_: any, filters: any, sorter: any) => {
    setSort(sorter);
  };

  return (
    <S.Wrap>
      <TableAnt
        columns={columns}
        dataSource={items.map((org: IOrganization) => ({
          key: org.id,
          ...org,
        }))}
        pagination={false}
        onChange={onChange}
      />
    </S.Wrap>
  );
};

export default Table;
