import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Drawer,
  Button,
  Input,
  InputNumber,
  Form,
  Image,
  DatePicker,
  Select,
  Rate,
  Tag,
} from "antd";
import {
  ClockCircleOutlined,
  CloseOutlined,
  FileImageOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  EditOrganizationModalDataSelector,
  uploadPhotos,
  uploadLogotype,
  EditOrganizationModalPhotosLoadingSelector,
  EditOrganizationModalLogotypeLoadingSelector,
  addLike,
  removePhoto,
  addMultiSelect,
  removeMultiSelect,
  changeOrganization,
  EditOrganizationModalVisibleSelector,
  closeModal,
  changeArrayItem,
  removeArrayItem,
  addArrayItem,
  getOrganizationById,
  removeLogotype,
} from "./EditOrganizationModalSlice";
import * as S from "./EditOrganizationModal.styled";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { useSelector } from "react-redux";
import { UserIdSelector } from "features/User/UserSlice";
import {
  IBrand,
  ICategory,
  ICriterion,
  IDay,
  IInterval,
  ILike,
  IMark,
  IMeasure,
  IPhoto,
  IReview,
  IService,
  ITag,
  IUser,
} from "interfaces/main";
import dayjs from "dayjs";
import {
  BrandsSelector,
  CategoriesSelector,
  CriteriaSelector,
  DaysSelector,
  MeasuresSelector,
  TagsSelector,
} from "features/Libraries/LibrariesSlice";
import {
  findUsersByName,
  SearchedUsersSelector,
  UsersLoadingSelector,
} from "features/UsersList/UsersListSlice";
import { exchangeOrganization } from "features/OrganizationsList/OrganizationsListSlice";
import { defaultValues } from "consts/default";
import { unwrapResult } from "@reduxjs/toolkit";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const { TextArea } = Input;
const { Option } = Select;

const EditOrganizationModal: React.FC = () => {
  // = = = = = = INIT = = = = = = =

  const dispatch = useAppDispatch();

  // Текст запроса для поиска по пользователям
  const [query, setQuery] = useState("");

  const [form] = Form.useForm();

  const photosInputRef = useRef<HTMLInputElement | null>(null);
  const logotypeInputRef = useRef<HTMLInputElement | null>(null);

  // Запуск поиска по людям
  useEffect(() => {
    dispatch(findUsersByName(query));
  }, [query]);

  // = = = = = = SELECTORS = = = = = = =

  // Данные организации
  const data = useAppSelector(EditOrganizationModalDataSelector);

  // Найденные пользователи
  const users = useAppSelector(SearchedUsersSelector);

  // Загрузка людей
  const isLoadingUsers = useAppSelector(UsersLoadingSelector);

  // Видимость дровера
  const isOrganizationModalVisible = useAppSelector(
    EditOrganizationModalVisibleSelector
  );
  // Мой личный идентивикатор
  const myUserId = useSelector(UserIdSelector);

  // Загрузка фоторгафий
  const isPhotosLoading = useSelector(
    EditOrganizationModalPhotosLoadingSelector
  );
  // Загрузка логотипа
  const isLogotypeLoading = useSelector(
    EditOrganizationModalLogotypeLoadingSelector
  );

  // Словари
  const allBrands = useAppSelector(BrandsSelector);
  const allTags = useAppSelector(TagsSelector);
  const allCategories = useAppSelector(CategoriesSelector);
  const allDays = useAppSelector(DaysSelector);
  const allMeasures = useAppSelector(MeasuresSelector);
  const allCriteria = useAppSelector(CriteriaSelector);

  const {
    logotype,
    id,
    title,
    description,
    phone,
    site,
    email,
    inn,
    longitude,
    latitude,
    categories,
    tags,
    brands,
    photos,
    reviews,
    createdAt,
    updatedAt,
    boxesCount,
    intervals,
    services,
    marks,
    likes,
  } = data;

  // Получение данных организации
  useEffect(() => {
    if (id)
      dispatch(getOrganizationById({ id }))
        .then(unwrapResult)
        .then((fetchedOrganization) => {
          const org = {
            ...fetchedOrganization,
            categories: fetchedOrganization.categories.map(
              (item: ICategory) => item.id
            ),
            tags: fetchedOrganization.tags.map((item: ITag) => item.id),
            brands: fetchedOrganization.brands.map((item: IBrand) => item.id),
          };

          const fields = Object.keys(org).map((fieldName: string) => ({
            name: fieldName,
            value: org[fieldName],
          }));

          form.setFields(fields);
        });
  }, [id]);

  // Id's пользователей, которые поставили лайк
  const likesUsersIds = likes.map((like: ILike) => like.userId);

  // Id's критериев, которые применены
  const criteriaIds = marks.map((mark: IMark) => mark.criterionId);

  // = = = = = = METHODS = = = = = = =

  // Закрытие окна
  const onClose = () => {
    form.submit();
  };

  // Загрузка новых фотографий
  const onUploadPhotos = (event: ChangeEvent<HTMLInputElement>) => {
    let newFileList = [...event.target.files];

    if (!!newFileList.length) {
      dispatch(uploadPhotos({ files: newFileList, organizationId: id }));
    }
  };

  // Загрузка логотипа
  const onUploadLogotype = (event: ChangeEvent<HTMLInputElement>) => {
    let newFile = event.target.files[0];

    if (!!newFile) {
      dispatch(uploadLogotype({ file: newFile, organizationId: id }));
    }
  };

  const onUploadPhotosClick = () => {
    photosInputRef.current?.click();
  };

  const onUploadLogotypeClick = () => {
    logotypeInputRef.current?.click();
  };

  const onRemovePhoto = (id: number) => {
    dispatch(removePhoto({ id }));
  };

  const onSubmit = () => {
    dispatch(exchangeOrganization(data));
    dispatch(closeModal());
  };

  const onRemoveLogotype = () => {
    dispatch(removeLogotype(logotype.id));
  };

  // Ошибки в форме при сабмите
  const onError = (errors) => console.log(errors);

  // = = = = = = = = = НОВЫЕ ПРЯМЫЕ МОТОДЫ = = = = = = = = =

  // Изменение основных полей организации
  const onChangeOrganization = (
    key: string,
    value: string | number | boolean
  ) => dispatch(changeOrganization({ key, organizationId: id, value }));

  // Изменение текстового поля
  const onChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => onChangeOrganization(event.target.name, event.target.value);

  // Изменение числового поля
  const onChangeNumberField = (name: string, value: number | string) =>
    onChangeOrganization(name, value);

  // Добавление в мультиселектах
  const onAddMultiSelect = (
    objectId: number,
    key: "brand" | "tag" | "category"
  ) => dispatch(addMultiSelect({ objectId, organizationId: id, key }));

  // Добавление в мультиселектах
  const onRemoveMultiSelect = (
    objectId: number,
    key: "brand" | "tag" | "category"
  ) => dispatch(removeMultiSelect({ objectId, organizationId: id, key }));
  // Добавление элемента массива
  const onAddArrayItem = (
    arrayName: "service" | "interval" | "review" | "mark"
  ) =>
    dispatch(
      addArrayItem({
        arrayName,
        item: {
          ...defaultValues[arrayName],
          organizationId: id,
          ...(arrayName === "review" ? { userId: myUserId } : {}),
        },
      })
    );

  // Изменение элемента массива
  const onChangeArrayItem = (
    arrayName: "service" | "interval" | "review" | "mark",
    itemId: number,
    value: string | number | boolean,
    key: string
  ) => dispatch(changeArrayItem({ arrayName, key, itemId, value }));

  // Удаление элемента массива
  const onRemoveArrayItem = (
    arrayName: "service" | "interval" | "review" | "mark" | "like",
    itemId: number
  ) => dispatch(removeArrayItem({ arrayName, itemId }));

  // Удаление одного лайка
  // const onRemoveLike = (likeId: number) => dispatch(removeLike(likeId));

  // При выборе пользователя
  const onSelectUser = (u: any) => {
    setQuery("");
    dispatch(addLike({ userId: u, organizationId: id }));
  };

  return (
    <Drawer
      title="Edit tech. center"
      placement="right"
      width={800}
      onClose={onClose}
      open={isOrganizationModalVisible}
    >
      <Form
        {...layout}
        name="editOrganizationForm"
        id="editOrganizationForm"
        form={form}
        initialValues={{
          description,
          email,
          photos,
          inn,
          phone,
          site,
          latitude,
          longitude,
          title,
          categories: categories.map((item: ICategory) => item.id),
          tags: tags.map((item: ITag) => item.id),
          brands: brands.map((item: IBrand) => item.id),
          createdAt,
          updatedAt,
          boxesCount,
          reviews,
          marks,
          services,
        }}
        onFinish={onSubmit}
        onError={onError}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            { required: true, message: "Please input organization's title!" },
          ]}
        >
          <Input
            type="text"
            placeholder="zDegree DIP"
            name="title"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Address"
          rules={[
            { required: true, message: "Please input organization's title!" },
          ]}
        >
          <TextArea
            style={{ width: "100%" }}
            rows={3}
            placeholder="129, 318 Roud, Al Quoz Fourth - Dubai"
            maxLength={100}
            name="description"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="phone" label="Phone">
          <Input
            type="text"
            placeholder="+971 4 3381551"
            name="phone"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="site" label="Site">
          <Input
            type="text"
            placeholder="www.volkswagen.ru"
            name="site"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input
            type="text"
            placeholder="info@volkswagen.ru"
            name="email"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item
          name="inn"
          label="INN"
          rules={[
            { required: true, message: "Please input organization's INN!" },
          ]}
        >
          <Input
            type="text"
            placeholder="0000000000"
            name="inn"
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item
          name="latitude"
          label="Latitude"
          rules={[{ required: true, message: "Please input latitude!" }]}
        >
          <InputNumber
            type="text"
            name="latitude"
            placeholder="24.199216"
            style={{ width: "100%" }}
            onChange={(value) => onChangeNumberField("latitude", value)}
          />
        </Form.Item>
        <Form.Item
          name="longitude"
          label="Longitude"
          rules={[{ required: true, message: "Please input longitude!" }]}
        >
          <InputNumber
            type="text"
            name="longitude"
            placeholder="55.760372"
            style={{ width: "100%" }}
            onChange={(value) => onChangeNumberField("longitude", value)}
          />
        </Form.Item>
        <Form.Item name="boxesCount" label="Boxes">
          <InputNumber
            type="text"
            name="boxesCount"
            placeholder="10"
            style={{ width: "100%" }}
            onChange={(value) => onChangeNumberField("boxesCount", value)}
          />
        </Form.Item>
        <Form.Item name="categories" label="Categories">
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select"
            onSelect={(key: number) => onAddMultiSelect(key, "category")}
            onDeselect={(key: number) => onRemoveMultiSelect(key, "category")}
          >
            {allCategories.map((item: ICategory) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="tags" label="Tags">
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select"
            onSelect={(key: number) => onAddMultiSelect(key, "tag")}
            onDeselect={(key: number) => onRemoveMultiSelect(key, "tag")}
          >
            {allTags.map((item: ITag) => (
              <Option key={item.id} value={item.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="brands" label="Brands">
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select"
            onSelect={(key: number) => onAddMultiSelect(key, "brand")}
            onDeselect={(key: number) => onRemoveMultiSelect(key, "brand")}
          >
            {allBrands.map((item: IBrand) => (
              <Option key={item.id} value={item.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={`Logotype (${logotype ? 1 : 0}/1)`}>
          {isLogotypeLoading && <S.Loader />}
          <input
            type="file"
            ref={logotypeInputRef}
            name="logotype"
            onChange={onUploadLogotype}
            style={{ display: "none" }}
          />
          <Button
            type="dashed"
            icon={<FileImageOutlined />}
            onClick={onUploadLogotypeClick}
            style={{ width: "100%" }}
            disabled={!!logotype}
          >
            Upload logotype
          </Button>
          <S.Images>
            {!!logotype && (
              <S.Image>
                <S.Remove onClick={onRemoveLogotype}>
                  <CloseOutlined
                    style={{
                      fontSize: "8px",
                      color: "#000",
                      width: "8px",
                      display: "block",
                    }}
                  />
                </S.Remove>
                <Image
                  src={logotype.url}
                  alt={logotype.fileName}
                  width={84}
                  height={84}
                  style={{ objectFit: "cover" }}
                />
              </S.Image>
            )}
          </S.Images>
        </Form.Item>
        <Form.Item label={`Photos (${photos.length}/10)`}>
          {isPhotosLoading && <S.Loader />}
          <input
            type="file"
            ref={photosInputRef}
            name="photos"
            onChange={onUploadPhotos}
            style={{ display: "none" }}
            multiple
            maxLength={10}
          />
          <Button
            type="dashed"
            icon={<FileImageOutlined />}
            onClick={onUploadPhotosClick}
            style={{ width: "100%" }}
            disabled={photos.length > 9}
          >
            Upload photos
          </Button>
          <S.Images>
            {photos.map((photo: IPhoto) => (
              <S.Image key={photo.id}>
                <S.Remove onClick={() => onRemovePhoto(photo.id)}>
                  <CloseOutlined
                    style={{
                      fontSize: "8px",
                      color: "#000",
                      width: "8px",
                      display: "block",
                    }}
                  />
                </S.Remove>
                <Image
                  src={photo.url}
                  alt={photo.fileName}
                  width={84}
                  height={84}
                  style={{ objectFit: "cover" }}
                />
              </S.Image>
            ))}
          </S.Images>
        </Form.Item>
        <Form.Item label="Reviews">
          {reviews.map((review: IReview) => (
            <S.Service key={review.id}>
              <Input.Group>
                <TextArea
                  style={{ width: "100%", borderRadius: 6 }}
                  rows={3}
                  placeholder="text review"
                  maxLength={100}
                  value={review.text}
                  onChange={(event) =>
                    onChangeArrayItem(
                      "review",
                      review.id,
                      event.target.value,
                      "text"
                    )
                  }
                />
              </Input.Group>
              <Input.Group style={{ display: "flex" }}>
                <Input
                  style={{ marginRight: 6, flexGrow: 1, borderRadius: 6 }}
                  placeholder="Source"
                  value={review.source}
                  onChange={(event) =>
                    onChangeArrayItem(
                      "review",
                      review.id,
                      event.target.value,
                      "source"
                    )
                  }
                />
                <Select
                  defaultValue={review.isRecommended}
                  style={{ width: "15%", marginRight: 6 }}
                  onChange={(value) =>
                    onChangeArrayItem(
                      "review",
                      review.id,
                      value,
                      "isRecommended"
                    )
                  }
                  placeholder="Select..."
                  options={[
                    { value: true, label: "yes" },
                    { value: false, label: "no" },
                  ]}
                />
                <DatePicker
                  placeholder="1"
                  style={{ width: "350px", marginRight: 6 }}
                  defaultValue={dayjs(review.createdAt)}
                  value={dayjs(review.createdAt)}
                  disabled
                />
                <Button
                  onClick={() => onRemoveArrayItem("review", review.id)}
                  style={{ width: "18%" }}
                >
                  remove
                </Button>
              </Input.Group>
            </S.Service>
          ))}
          <Button
            style={{ width: "100%" }}
            onClick={() => onAddArrayItem("review")}
            type="dashed"
          >
            <PlusOutlined />
            add review
          </Button>
        </Form.Item>
        <Form.Item label="Working hours">
          {intervals.map((interval: IInterval) => (
            <S.Period key={interval.id}>
              <Input.Group style={{ display: "flex" }}>
                <Select
                  defaultValue={interval.dayId}
                  style={{ marginRight: 6, flexGrow: 1 }}
                  placeholder="Select..."
                  onChange={(value: number) =>
                    onChangeArrayItem("interval", interval.id, value, "dayId")
                  }
                >
                  {allDays.map((item: IDay) => (
                    <Option value={item.id} key={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
                <Input
                  style={{ marginRight: 6, flexGrow: 1, borderRadius: 6 }}
                  placeholder="08:00 - 20:00"
                  value={interval.timeInterval}
                  onChange={(event) =>
                    onChangeArrayItem(
                      "interval",
                      interval.id,
                      event.target.value,
                      "timeInterval"
                    )
                  }
                />
                <Button
                  onClick={() => onRemoveArrayItem("interval", interval.id)}
                  style={{ width: "18%" }}
                >
                  remove
                </Button>
              </Input.Group>
            </S.Period>
          ))}
          {intervals.length < 7 && (
            <Button
              style={{ width: "100%" }}
              onClick={() => onAddArrayItem("interval")}
              type="dashed"
            >
              <ClockCircleOutlined />
              add interval
            </Button>
          )}
        </Form.Item>
        <Form.Item label="Services">
          {services.map((service: IService) => (
            <S.Service key={service.id}>
              <Input.Group style={{ display: "flex" }}>
                <Input
                  style={{ marginRight: 6, flexGrow: 1, borderRadius: 6 }}
                  placeholder="Oil change"
                  value={service.title}
                  onChange={(event) =>
                    onChangeArrayItem(
                      "service",
                      service.id,
                      event.target.value,
                      "title"
                    )
                  }
                />
                <InputNumber
                  min={0}
                  max={100000}
                  step={1}
                  placeholder="1"
                  style={{ marginRight: 6, width: "200px" }}
                  defaultValue={service.price}
                  value={service.price}
                  onChange={(value) =>
                    onChangeArrayItem("service", service.id, value, "price")
                  }
                />
                <Select
                  defaultValue={
                    allMeasures.find(
                      (item: IMeasure) => item.id === service.measureId
                    )?.title || ""
                  }
                  style={{ width: "200px", marginRight: 6 }}
                  onChange={(value) =>
                    onChangeArrayItem("service", service.id, value, "measureId")
                  }
                >
                  {allMeasures.map((item: IMeasure) => (
                    <Option value={item.id} key={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
                <Button
                  onClick={() => onRemoveArrayItem("service", service.id)}
                  style={{ width: "18%" }}
                >
                  remove
                </Button>
              </Input.Group>
            </S.Service>
          ))}
          <Button
            onClick={() => onAddArrayItem("service")}
            type="dashed"
            style={{ width: "100%" }}
          >
            <PlusOutlined />
            add service
          </Button>
        </Form.Item>
        <Form.Item label="Marks">
          {marks.map((mark: IMark) => (
            <S.Period key={mark.id}>
              <Input.Group style={{ display: "flex" }}>
                <Select
                  style={{ flexGrow: 1 }}
                  placeholder="Please select"
                  onChange={(value: number) =>
                    onChangeArrayItem("mark", mark.id, value, "criterionId")
                  }
                  defaultValue={mark.criterionId}
                >
                  {allCriteria.map((criterion: ICriterion) => (
                    <Option
                      key={criterion.id}
                      value={criterion.id}
                      disabled={criteriaIds.includes(criterion.id)}
                    >
                      {criterion.title}
                    </Option>
                  ))}
                </Select>
                <Rate
                  defaultValue={mark.value}
                  onChange={(rate) =>
                    onChangeArrayItem("mark", mark.id, rate, "value")
                  }
                  style={{ width: "35%", textAlign: "center" }}
                />
                <Button
                  onClick={() => onRemoveArrayItem("mark", mark.id)}
                  style={{ width: "18%" }}
                >
                  remove
                </Button>
              </Input.Group>
            </S.Period>
          ))}
          {marks.length < allCriteria.length && (
            <Button
              onClick={() => onAddArrayItem("mark")}
              style={{ width: "100%" }}
              type="dashed"
            >
              <ClockCircleOutlined />
              add mark
            </Button>
          )}
        </Form.Item>
        <Form.Item label="Likes">
          <Input.Group style={{ display: "flex" }}>
            <Select
              showSearch
              value={query}
              options={users
                .filter((user: IUser) => !likesUsersIds.includes(user.id))
                .map((user: IUser) => ({
                  label: user.name,
                  value: user.id,
                }))}
              filterOption={false}
              loading={isLoadingUsers}
              style={{ flexGrow: 1 }}
              onSearch={setQuery}
              onSelect={onSelectUser}
              placeholder="Select a user"
            />
          </Input.Group>
          {likes.map((like: ILike) => (
            <Tag key={like.id} style={{ marginTop: "8px" }}>
              {like.userId}
              <CloseOutlined
                onClick={() => onRemoveArrayItem("like", like.id)}
              />
            </Tag>
          ))}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditOrganizationModal;
