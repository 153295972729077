import styled from "styled-components";

export const Period = styled.div`
  margin-bottom: 6px;
`;

export const Service = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 6px;
`;

export const Images = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Remove = styled.div`
  display: none;
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
`;

export const Image = styled.div`
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  position: relative;
  :hover ${Remove} {
    display: flex;
  }
`;

export const UnloadedImages = styled.ul`
  opacity: 0.5;
`;

export const Loader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.9);
`;
