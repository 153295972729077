import React from "react";
import Helmet from "react-helmet";
import { metaData } from "../../consts/meta";

export interface IMetaProps {
  pageName:
    | "login"
    | "signUp"
    | "messages"
    | "myServices"
    | "records"
    | "services"
    | "users"
    | "logs";
}

const Meta: React.FC<IMetaProps> = ({ pageName }) => (
  <Helmet>
    <title>{metaData[pageName].title}</title>
    <meta name="description" content={metaData[pageName].description} />
    <meta name="keywords" content={metaData[pageName].keywords} />
  </Helmet>
);

export default Meta;
