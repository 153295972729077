import styled from "styled-components";

export const WorkSpace = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin-bottom: 16px;
`;

export const Search = styled.div`
  margin-bottom: 16px;
  padding: 12px;
  background-color: #FFF;
  border-radius: 2px;
`;

export const SearchIcon = styled.div`
  margin-right: 4px;
`;
