import React from "react";
import { Input, Form, Modal, InputNumber } from "antd";
import {
  closeModal,
  // changeField,
  CreateOrganizationModalVisibleSelector,
} from "./CreateOrganizationModalSlice";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
// import { useSelector } from "react-redux";
import {
  createOrganization,
  CreateOrganizationModalDataSelector,
} from "./CreateOrganizationModalSlice";
import * as S from "./CreateOrganizationModal.styled";
import { IOrganization } from "interfaces/main";
import { unwrapResult } from "@reduxjs/toolkit";

// const { Option } = Select;
const { TextArea } = Input;

const GroupModal: React.FC = () => {
  const dispatch = useAppDispatch();

  // Инициализация формы
  const [form] = Form.useForm();

  const { userId } = useAppSelector(CreateOrganizationModalDataSelector);
  const data = useAppSelector(CreateOrganizationModalDataSelector);

  // Закрытие окна
  const onClose = () => {
    form.resetFields();
    dispatch(closeModal());
  };

  // Изменение текстового поля
  // const onChangeTextField = (
  //   event:
  //     | React.ChangeEvent<HTMLInputElement>
  //     | ChangeEvent<HTMLTextAreaElement>
  // ) => dispatch(changeField(event.target.name, event.target.value));

  // Изменение поля
  // const onChangeField = (name: string, value: boolean | number) =>
  //   dispatch(changeField(name, value));

  // Отправка формы
  const onSubmit = (organization: IOrganization) => {
    // form.resetFields();
    dispatch(createOrganization({ ...organization, userId }))
      .then(unwrapResult)
      .then((organization: IOrganization) => {
        if (organization.id) {
          form.resetFields();
        }
      });
  };

  // Видимость модального окна
  const isVisible = useAppSelector(CreateOrganizationModalVisibleSelector);

  // useEffect(
  //   () =>
  //     ,
  //   [isVisible]
  // );

  return (
    <Modal
      open={isVisible}
      title="Create new organization"
      okText="Create"
      cancelText="Cancel"
      onCancel={onClose}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // form.resetFields();
            onSubmit(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="groupForm" initialValues={data}>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            { required: true, message: "Please input organization's title!" },
          ]}
        >
          <Input
            type="text"
            placeholder="Profix Auto Care Garage"
            name="title"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Address"
          rules={[
            { required: true, message: "Please input organization's title!" },
          ]}
        >
          <TextArea
            style={{ width: "100%" }}
            rows={3}
            placeholder="129, 318 Roud, Al Quoz Fourth - Dubai"
            maxLength={100}
            name="description"
            // onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="phone" label="Phone">
          <Input
            type="text"
            placeholder="+971 4 3381551"
            name="phone"
            // onChange={onChangeTextField}
          />
        </Form.Item>
        <S.Line>
          <Form.Item name="site" style={{ flexGrow: 1 }} label="Site">
            <Input
              type="text"
              placeholder="www.volkswagen.ru"
              name="site"
              // onChange={onChangeTextField}
            />
          </Form.Item>
          <Form.Item name="email" style={{ flexGrow: 1 }} label="Email">
            <Input
              type="text"
              placeholder="info@volkswagen.ru"
              name="email"
              // onChange={onChangeTextField}
            />
          </Form.Item>
        </S.Line>
        <S.Line>
          <Form.Item
            name="latitude"
            label="Latitude"
            style={{ flexGrow: 1 }}
            rules={[{ required: true, message: "Please input latitude!" }]}
          >
            <InputNumber
              type="text"
              name="latitude"
              placeholder="24.199216"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="longitude"
            label="Longitude"
            style={{ flexGrow: 1 }}
            rules={[{ required: true, message: "Please input longitude!" }]}
          >
            <InputNumber
              type="text"
              name="longitude"
              placeholder="55.760372"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </S.Line>
        <S.Line>
          <Form.Item name="boxesCount" label="Boxes" style={{ flexGrow: 1 }}>
            <InputNumber
              type="text"
              name="boxesCount"
              placeholder="10"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="inn"
            label="INN"
            style={{ flexGrow: 1 }}
            rules={[
              { required: true, message: "Please input organization's INN!" },
            ]}
          >
            <Input
              type="text"
              placeholder="0000000000"
              name="inn"
              // onChange={onChangeTextField}
            />
          </Form.Item>
        </S.Line>
      </Form>
    </Modal>
  );
};

export default GroupModal;
