import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { IMessagesListState } from "./interfaces";

// Селекторы
export const MessagesListSelector = (state: RootState): IMessagesListState =>
  state.messagesList;
export const MessagesListLoadingSelector = createSelector(
  MessagesListSelector,
  ({ isLoading }) => isLoading
);
export const MessagesListItemsSelector = createSelector(
  MessagesListSelector,
  ({ items }) => items
);

const initialState: IMessagesListState = {
  isLoading: false,
  items: [],
};

const messagesListSlice = createSlice({
  name: "messagesList",
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default messagesListSlice.reducer;
