import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { IUserModalState } from "./interfaces";
import { IUserData } from "features/User/interfaces";

// Открытие окна с существующей целью
export const openOldUserModal = createAction(
  "userModal/openOldUserModal",
  (user: IUserData) => ({ payload: user })
);

// Открытие окна с новой целью
export const closeUserModal = createAction("userModal/closeUserModal");

// Изменение полей
export const changeField = createAction(
  "userModal/changeField",
  (field: string, value: string | number | boolean) => ({
    payload: { field, value },
  })
);

const initialState: IUserModalState = {
  id: "",
  uid: "",
  email: "",
  phone: "",
  name: "",
  admin: false,
  onboarding: false,
  cars: [],
  photo: "",
  manager: false,
  type: "",
  createdDate: Date.now().valueOf(),
  balance: 0,
  isLoading: false,
  isVisible: false,
};

const userModalSlice = createSlice({
  name: "userModal",
  initialState,
  reducers: {
    closeUserModal: () => initialState,
    openOldUserModal: (_, { payload }) => ({
      ...initialState,
      ...payload,
      isVisible: true,
    }),
    changeField: (state, { payload }) => ({
      ...state,
      [payload.field]: payload.value,
    }),
  },
  extraReducers: () => {},
});

// Селекторы
export const UserModalSelector = (state: RootState): IUserModalState =>
  state.userModal;
export const UserModalLoadingSelector = createSelector(
  UserModalSelector,
  ({ isLoading }) => isLoading
);
export const UserModalVisibleSelector = createSelector(
  UserModalSelector,
  ({ isVisible }) => isVisible
);

export default userModalSlice.reducer;
