import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { getRequest, postRequest, setToken } from "api/requests";
import { IUser } from "interfaces/main";
import { RootState } from "store/store";
import { IUserState } from "./interfaces";

// Выход
export const logOut = createAction("user/logOut");

// Авторизация
export const login = createAsyncThunk(
  "user/login",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await postRequest("user/loginByEmail", {
        email,
        password,
      });

      const { user, token } = response.data;

      setToken(token);

      return user;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Регистрация
export const registration = createAsyncThunk(
  "user/registration",
  async (
    {
      email,
      password,
      name,
      phone,
    }: { email: string; phone: string; password: string; name: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await postRequest("user/registrationByEmail", {
        email,
        password,
        phone,
        name,
      });

      const { user, token } = response.data;

      setToken(token);

      return user;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Проверка имеющегося ключа
export const checkToken = createAsyncThunk(
  "user/checkToken",
  async (_blank, { rejectWithValue }) => {
    try {
      const response = await getRequest("user/checkToken", {}, true);

      const { user, token } = response.data;

      setToken(token);

      return user;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Загрузка пользователя по ID
export const getUserById = createAsyncThunk(
  "user/getUserById",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await getRequest(`user/${id}`, {}, true);
      const userData = response.data;
      const user: IUser = {
        id: userData?.id || null,
        email: userData?.email || "",
        name: userData?.name || "",
        balance: userData?.balance || "",
        onboarding: userData?.onboarding || false,
        roleId: userData?.roleId || 1,
        userTypeId: userData?.userTypeId || 1,
        avatar: userData?.avatar || null,
        accepted: userData?.accepted || false,
        createdAt: userData?.createdAt || "",
        updatedAt: userData?.updatedAt || "",
        phone: userData?.phone || "",
      };

      return user;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IUserState = {
  isLoading: false,
  id: 0,
  email: "",
  phone: "",
  name: "",
  balance: 0,
  avatar: null,
  accepted: false,
  onboarding: false,
  roleId: 1,
  userTypeId: 1,
  createdAt: "",
  updatedAt: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logOut: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(login.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(login.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        isLoading: false,
      }))
      .addCase(registration.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(registration.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(registration.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        isLoading: false,
      }))
      .addCase(checkToken.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(checkToken.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(checkToken.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        isLoading: false,
      }))
      .addCase(getUserById.pending, (state) => ({ ...state, isLoading: true }))
      .addCase(getUserById.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(getUserById.fulfilled, (state) => ({
        ...state,
        isLoading: false,
      }));
  },
});

// Селекторы
export const UserSelector = (state: RootState): IUserState => state.user;

export const UserIdSelector = createSelector(UserSelector, ({ id }) => id);

export const IsAuthenticatedSelector = createSelector(
  UserSelector,
  ({ id }) => !!id
);

export const AcceptedSelector = createSelector(
  UserSelector,
  ({ accepted }) => accepted
);

export const AdminSelector = createSelector(UserSelector, ({ roleId }) =>
  [2, 3, 4, 5].includes(roleId)
);

export const NeedOnboardingSelector = createSelector(
  UserSelector,
  ({ onboarding }) => !!onboarding
);

export const UserLoadingSelector = createSelector(
  UserSelector,
  ({ isLoading }) => isLoading
);

export default userSlice.reducer;
