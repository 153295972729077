import React from "react";
import { Route, Switch } from "react-router-dom";
import Organizations from "pages/Organizations";
import Records from "pages/Records";

const PrivateRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/">
      <Organizations />
    </Route>
    <Route exact path="/records">
      <Records />
    </Route>
  </Switch>
);

export default PrivateRoutes;
