import { EditOrganizationModalLoadingSelector } from "features/EditOrganizationModal/EditOrganizationModalSlice";
import { OrganizationsListLoadingSelector } from "features/OrganizationsList/OrganizationsListSlice";
import { MessagesListLoadingSelector } from "features/MessagesList/MessagesListSlice";
import { EditRecordModalLoadingSelector } from "features/EditRecordModal/EditRecordModalSlice";
import { RecordsListLoadingSelector } from "features/RecordsList/RecordsListSlice";
import { LogsListLoadingSelector } from "features/LogsList/LogsListSlice";
import {
  BrandsLoadingSelector,
  CategoriesLoadingSelector,
  DaysLoadingSelector,
  LogTypesLoadingSelector,
  UserTypesLoadingSelector,
  RolesLoadingSelector,
  CriteriaLoadingSelector,
  StatusesLoadingSelector,
  TagsLoadingSelector,
  MeasuresLoadingSelector,
} from "features/Libraries/LibrariesSlice";
import { useAppSelector } from "hooks/useStore";

import "./Loader.css";
import { UserLoadingSelector } from "features/User/UserSlice";
import { UsersListLoadingSelector } from "features/UsersList/UsersListSlice";

const Loader = () => {
  const userLoading = useAppSelector(UserLoadingSelector);
  const usersListLoading = useAppSelector(UsersListLoadingSelector);
  const organizationsListLoading = useAppSelector(
    OrganizationsListLoadingSelector
  );
  const messagesLoading = useAppSelector(MessagesListLoadingSelector);
  const editOrganizationModalLoading = useAppSelector(
    EditOrganizationModalLoadingSelector
  );
  const recordsLoading = useAppSelector(RecordsListLoadingSelector);
  const editRecordModalLoading = useAppSelector(EditRecordModalLoadingSelector);
  const brandsLoading = useAppSelector(BrandsLoadingSelector);
  const categoriesLoading = useAppSelector(CategoriesLoadingSelector);
  const daysLoading = useAppSelector(DaysLoadingSelector);
  const rolesLoading = useAppSelector(RolesLoadingSelector);
  const logTypesLoading = useAppSelector(LogTypesLoadingSelector);
  const userTypesLoading = useAppSelector(UserTypesLoadingSelector);
  const criteriaLoading = useAppSelector(CriteriaLoadingSelector);
  const statusesLoading = useAppSelector(StatusesLoadingSelector);
  const tagsLoading = useAppSelector(TagsLoadingSelector);
  const measuresLoading = useAppSelector(MeasuresLoadingSelector);
  const logsListLoading = useAppSelector(LogsListLoadingSelector);

  return (
    <>
      {(messagesLoading ||
        organizationsListLoading ||
        editOrganizationModalLoading ||
        recordsLoading ||
        editRecordModalLoading ||
        brandsLoading ||
        categoriesLoading ||
        daysLoading ||
        rolesLoading ||
        logTypesLoading ||
        userTypesLoading ||
        criteriaLoading ||
        tagsLoading ||
        userLoading ||
        usersListLoading ||
        measuresLoading ||
        logsListLoading ||
        statusesLoading) && (
        <div className="modal-backdrop">
          <span className="spinner"></span>
        </div>
      )}
    </>
  );
};

export default Loader;
