import { Layout, Button } from "antd";
import React, { useEffect } from "react";
import Navbar from "components/Navbar";
import Loader from "components/Loader";
import Windows from "components/Windows";

import PrivateRoutes from "privateRoutes";
import "./App.css";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import Login from "pages/Login";
import SignUp from "pages/SignUp";
import { Route, useHistory, Redirect } from "react-router-dom";
import {
  checkToken,
  IsAuthenticatedSelector,
  logOut,
} from "features/User/UserSlice";
import { getToken, removeToken } from "api/requests";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  fetchBrands,
  fetchCategories,
  fetchCriteria,
  fetchDays,
  fetchTags,
  fetchRoles,
  fetchLogTypes,
  fetchMeasures,
  fetchStatuses,
  fetchUserTypes,
} from "features/Libraries/LibrariesSlice";

const { Header, Footer } = Layout;

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const isAuthenticated = useAppSelector(IsAuthenticatedSelector);

  useEffect(() => {
    const token = getToken();

    if (token)
      dispatch(checkToken())
        .then(unwrapResult)
        .then(() => push("/"))
        .then(() => {
          dispatch(fetchDays());
          dispatch(fetchTags());
          dispatch(fetchBrands());
          dispatch(fetchMeasures());
          dispatch(fetchCategories());
          dispatch(fetchCriteria());
          dispatch(fetchStatuses());
          dispatch(fetchRoles());
          dispatch(fetchLogTypes());
          dispatch(fetchUserTypes());
        })
        .catch(console.error);
  }, []);

  const onLogOut = () => {
    dispatch(logOut());
    removeToken();
    push("/");
  };

  return (
    <Layout className="app">
      {isAuthenticated ? (
        <>
          <Header className="header">
            <Navbar />
            <Button onClick={onLogOut}>Log out</Button>
          </Header>
          <PrivateRoutes />
          <Footer className="footer">CAR SERVICES MANAGER | carcity.ae</Footer>
        </>
      ) : (
        <>
          <Redirect to="/login" />
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <SignUp />
          </Route>
        </>
      )}
      <Loader />
      <Windows />
    </Layout>
  );
};

export default App;
