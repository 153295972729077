import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { IUsersListState } from "./interfaces";
import { getRequest } from "api/requests";

// Actions
export const setQuery = createAction("usersList/setQuery", (query: string) => ({
  payload: query,
}));

export const findUsersByName = createAsyncThunk(
  "usersList/findUsersByName",
  async (query: string, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(`user/all?query=${query}`);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IUsersListState = {
  isLoading: false,
  isLoadingSearch: false,
  items: [],
  searchedUsers: [],
  query: "",
};

const usersListSlice = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    setQuery: (state, { payload }) => ({
      ...state,
      query: payload.toLocaleLowerCase(),
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(findUsersByName.pending, (state) => ({
        ...state,
        isLoadingSearch: true,
      }))
      .addCase(findUsersByName.rejected, (state) => ({
        ...state,
        isLoadingSearch: false,
      }))
      .addCase(findUsersByName.fulfilled, (state, { payload }) => ({
        ...state,
        isLoadingSearch: false,
        searchedUsers: payload,
      }));
  },
});

// Селекторы
export const UsersListSelector = (state: RootState): IUsersListState =>
  state.usersList;

export const UsersListLoadingSelector = createSelector(
  UsersListSelector,
  ({ isLoading }) => isLoading
);

export const UsersLoadingSelector = createSelector(
  UsersListSelector,
  ({ isLoadingSearch }) => isLoadingSearch
);

export const UsersListItemsSelector = createSelector(
  UsersListSelector,
  ({ items }) => items
);

export const SearchedUsersSelector = createSelector(
  UsersListSelector,
  ({ searchedUsers }) => searchedUsers
);

export const QuerySelector = createSelector(
  UsersListSelector,
  ({ query }) => query
);

export default usersListSlice.reducer;
