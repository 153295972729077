import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { ICreateOrganizationModalState } from "./interfaces";
import { postRequest } from "api/requests";
import { IOrganization } from "interfaces/main";
import { mapOrganization } from "utils/maping";

// Открытие окна с новой группой
export const openCreateOrganizationModal = createAction(
  "createOrganizationModal/openCreateOrganizationModal",
  ({ userId }: { userId: number }) => ({
    payload: { userId },
  })
);

// Закрытие окна с задачей
export const closeModal = createAction("createOrganizationModal/closeModal");

// Изменение полей
export const changeField = createAction(
  "createOrganizationModal/changeField",
  (field: string, value: string | number | boolean) => ({
    payload: { field, value },
  })
);

// Добавление новой группы
export const createOrganization = createAsyncThunk(
  "createOrganizationModal/createOrganization",
  async (newOrganization: IOrganization, { rejectWithValue }) => {
    try {
      const response = await postRequest("organization", newOrganization, true);

      return mapOrganization(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialOrganization: IOrganization = {
  id: null,
  title: "",
  description: "",
  phone: "",
  email: "",
  inn: "",
  site: "",
  longitude: null,
  latitude: null,
  boxesCount: null,
  userId: null,
};

const initialState: ICreateOrganizationModalState = {
  data: initialOrganization,
  isLoading: false,
  isVisible: false,
};

const createOrganizationModalSlice = createSlice({
  name: "createOrganizationModal",
  initialState,
  reducers: {
    openCreateOrganizationModal: (_, { payload }) => ({
      ...initialState,
      isVisible: true,
      data: {
        ...initialOrganization,
        ...payload,
      },
    }),
    closeModal: () => initialState,
    changeField: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        [payload.field]: payload.value,
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrganization.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(createOrganization.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(createOrganization.fulfilled, () => initialState);
  },
});

// Селекторы
export const CreateOrganizationModalSelector = (
  state: RootState
): ICreateOrganizationModalState => state.createOrganizationModal;

export const CreateOrganizationModalLoadingSelector = createSelector(
  CreateOrganizationModalSelector,
  ({ isLoading }) => isLoading
);

export const CreateOrganizationModalVisibleSelector = createSelector(
  CreateOrganizationModalSelector,
  ({ isVisible }) => isVisible
);

export const CreateOrganizationModalDataSelector = createSelector(
  CreateOrganizationModalSelector,
  ({ data }) => data
);

export default createOrganizationModalSlice.reducer;
