import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { IRecordsListState } from "./interfaces";
import { IRecord } from "interfaces/main";
import { deleteRequest, getRequest } from "api/requests";

// Селекторы
export const RecordsListSelector = (state: RootState): IRecordsListState =>
  state.recordsList;
export const RecordsListLoadingSelector = createSelector(
  RecordsListSelector,
  ({ isLoading }) => isLoading
);
export const RecordsListItemsSelector = createSelector(
  RecordsListSelector,
  ({ items }) => items
);

export const fetchRecords = createAsyncThunk(
  "records/fetchRecords",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("record", {}, true);

      return response.data.map(
        ({
          id,
          date,
          clientComment,
          organizationComment,
          organizationId,
          organization,
          carId,
          car,
          statusId,
          status,
          userId,
          user,
          createdAt,
          updatedAt,
          services,
        }): IRecord => ({
          id,
          date,
          clientComment,
          organizationComment,
          organizationId,
          organization,
          carId,
          car,
          statusId,
          status,
          userId,
          user,
          createdAt,
          updatedAt,
          services,
        })
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление существующего тех. центра
export const removeRecord = createAsyncThunk(
  "records/removeRecord",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await deleteRequest(`record/${id}`, {}, true);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Замена записи в списке
export const exchangeRecord = createAction(
  "records/exchangeRecord",
  (record: IRecord) => ({ payload: record })
);

const initialState: IRecordsListState = {
  isLoading: false,
  items: [],
};

const recordsListSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    exchangeRecord: (state, { payload }) => ({
      ...state,
      items: state.items.map((item: IRecord) =>
        item.id === payload.id ? payload : item
      ),
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecords.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(fetchRecords.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(fetchRecords.fulfilled, (state, { payload }) => ({
        ...state,
        items: payload,
        isLoading: false,
      }))
      .addCase(removeRecord.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(removeRecord.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(removeRecord.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        items: state.items.filter((item: IRecord) => item.id !== payload.id),
      }));
  },
});

export default recordsListSlice.reducer;
