import EditOrganizationModal from "features/EditOrganizationModal";
import CreateOrganizationModal from "features/CreateOrganizationModal";
import React from "react";
import EditRecordModal from "features/EditRecordModal";

const Windows: React.FC = () => {
  return (
    <>
      <CreateOrganizationModal />
      <EditOrganizationModal />
      <EditRecordModal />
    </>
  );
};

export default Windows;
