import { createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "store/store";
import { ILogsListState } from "./interfaces";

// Селекторы
export const LogsListSelector = (state: RootState): ILogsListState =>
  state.logsList;
export const LogsListLoadingSelector = createSelector(
  LogsListSelector,
  ({ isLoading }) => isLoading
);
export const LogsListItemsSelector = createSelector(
  LogsListSelector,
  ({ items }) => items
);

const initialState: ILogsListState = {
  isLoading: false,
  items: [],
};

const logsListSlice = createSlice({
  name: "logsList",
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default logsListSlice.reducer;
