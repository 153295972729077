import axios from "axios";
// = = = = = = = = SERVER = = = = = = =
// VKC.RU
// const DOMAIN = "http://95.163.180.72/";

// REG.RU
const DOMAIN = "http://95.163.242.213";

// Local backend methods
// const DOMAIN = "http://localhost";
// = = = = = = = = = = = = = = = = = = =

const PORT = "5555";
const BASE_PATH = "/api";

export const getUrl = () => `${DOMAIN}:${PORT}${BASE_PATH}/`;

// Проверка наличия ключа
export const getToken = () =>
  localStorage.getItem("carcity_authenticationToken");

// Сохранение ключа
export const setToken = (token: string) =>
  localStorage.setItem("carcity_authenticationToken", token);

// Удаление ключа
export const removeToken = () =>
  localStorage.removeItem("carcity_authenticationToken");

// GET REQUEST
export const makeGetRequest = (
  entitySet,
  serviceUrl,
  params = {},
  hasToken = false
) => {
  const headers = {};
  const token = getToken();

  if (hasToken && !!token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return axios.get(`${serviceUrl}${entitySet}`, {
    headers,
    params,
  });
};

// POST REQUEST
export const makePostRequest = (
  entitySet,
  serviceUrl,
  body = {},
  hasToken = false
) => {
  const headers = {};
  const token = getToken();

  if (hasToken && !!token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return axios.post(`${serviceUrl}${entitySet}`, body, {
    headers,
  });
};

// DELETE REQUEST
export const makeDeleteRequest = (
  entitySet,
  serviceUrl,
  params = {},
  hasToken = false
) => {
  const headers = {};
  const token = getToken();

  if (hasToken && !!token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return axios.delete(`${serviceUrl}${entitySet}`, {
    headers,
    data: params,
  });
};

// PUT REQUEST
export const makePutRequest = (
  entitySet,
  serviceUrl,
  params = {},
  hasToken = false
) => {
  const headers = {};
  const token = getToken();

  if (hasToken && !!token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return axios.put(`${serviceUrl}${entitySet}`, {
    headers,
    params,
  });
};

export const getRequest = (entity, params = {}, hasToken = false) =>
  makeGetRequest(entity, getUrl(), params, hasToken);

export const postRequest = (entity, params = {}, hasToken = false) =>
  makePostRequest(entity, getUrl(), params, hasToken);

export const putRequest = (entity, params = {}, hasToken = false) =>
  makePutRequest(entity, getUrl(), params, hasToken);

export const deleteRequest = (entity, params = {}, hasToken = false) =>
  makeDeleteRequest(entity, getUrl(), params, hasToken);
