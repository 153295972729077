import styled from "styled-components";

export const Wrap = styled.div`
  height: 100%;
  margin-bottom: 20px;
`;

export const Actions = styled.div`
  display: flex;
  gap: 4px;
`;
