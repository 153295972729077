import React, { FocusEvent, useEffect, useState } from "react";
import { Drawer, Input, Select, Form, DatePicker, Card, Button } from "antd";

import { useAppDispatch, useAppSelector } from "hooks/useStore";
import {
  BrandsSelector,
  MeasuresSelector,
  StatusSelector,
} from "features/Libraries/LibrariesSlice";
import {
  IBrand,
  ICar,
  IMeasure,
  IService,
  IStatus,
  IUser,
} from "interfaces/main";
import {
  closeModal,
  EditRecordModalDataSelector,
  EditRecordModalVisibleSelector,
  getRecordById,
  changeRecord,
  setField,
  findServiceByName,
  ServicesLoadingSelector,
  SearchedServicesSelector,
  removeService,
  addService,
  SearchedCarsSelector,
  CarsLoadingSelector,
  getCarsByUserId,
} from "./EditRecordModalSlice";
import dayjs from "dayjs";
import {
  findUsersByName,
  SearchedUsersSelector,
  UsersLoadingSelector,
} from "features/UsersList/UsersListSlice";
import User from "./User";
import { exchangeRecord } from "features/RecordsList/RecordsListSlice";
import * as S from "./EditRecordModal.styled";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const { Option } = Select;
const { TextArea } = Input;

const EditRecordModal: React.FC = () => {
  // Состояния поиски пользователей
  const [userQuery, setUserQuery] = useState("");

  // Состояния поиски пользователей
  const [serviceQuery, setServiceQuery] = useState("");

  const dispatch = useAppDispatch();

  // Инициализация формы
  const [form] = Form.useForm();

  // Данные записи
  const data = useAppSelector(EditRecordModalDataSelector);

  // Видимость дровера
  const isRecordModalVisible = useAppSelector(EditRecordModalVisibleSelector);

  // Найденные пользователи
  const searchedUsers = useAppSelector(SearchedUsersSelector);

  // Найденные услуги
  const searchedServices = useAppSelector(SearchedServicesSelector);

  // Найденные автомобили
  const searchedCars = useAppSelector(SearchedCarsSelector);

  // Загрузка людей
  const isLoadingUsers = useAppSelector(UsersLoadingSelector);

  // Загрузка услуг
  const isLoadingServices = useAppSelector(ServicesLoadingSelector);

  // Загрузка автомобилей
  const isLoadingCars = useAppSelector(CarsLoadingSelector);

  // Словари
  const allStatuses = useAppSelector(StatusSelector);
  const allMeasures = useAppSelector(MeasuresSelector);
  const allBrands = useAppSelector(BrandsSelector);

  const onSubmit = () => {
    dispatch(exchangeRecord(data));
    dispatch(closeModal());
  };

  // Закрытие окна
  const onClose = () => {
    form.submit();
  };

  // Ошибки в форме при сабмите
  const onError = (errors) => console.log(errors);

  const {
    id,
    date,
    clientComment,
    organizationComment,
    services,
    userId,
    user,
    car,
    carId,
    statusId,
    organizationId,
  } = data;

  // Id's услуг
  const servicesIds = services.map((service: IService) => service.id);

  // Получение данных записи
  useEffect(() => {
    if (id) dispatch(getRecordById({ id }));
  }, [id]);

  // Обновление данных в форме
  // useEffect(() => {
  //   const rec = {
  //     ...data,
  //     services: data.services.map((item: IService) => item.id),
  //   };

  //   const fields = Object.keys(rec).map((fieldName: string) => ({
  //     name: fieldName,
  //     value: fieldName === "date" ? dayjs(rec[fieldName]) : rec[fieldName],
  //   }));

  //   form.setFields(fields);
  // }, [data]);

  // Получение данных записи
  useEffect(() => {
    if (userId) dispatch(getCarsByUserId({ userId }));
  }, [userId]);

  // Выбор автомобиля
  const onSelectCar = (carId: any) => {
    const selectedCar = searchedCars.find((item: ICar) => item.id === carId);

    dispatch(
      changeRecord({ key: "carId", recordId: id, value: selectedCar.id })
    );

    dispatch(setField({ key: "car", value: selectedCar }));
  };

  // Сброс автомобиля
  const onRemoveCar = () => {
    dispatch(changeRecord({ key: "carId", recordId: id, value: null }));
    dispatch(setField({ key: "car", value: null }));
  };

  // Поиск пользователя
  const onSearchUser = (q: string) => {
    setUserQuery(q);
    dispatch(findUsersByName(q));
  };

  // Выбор пользователя
  const onSelectUser = (userId: any) => {
    const selectedUser = searchedUsers.find(
      (item: IUser) => item.id === userId
    );

    dispatch(
      changeRecord({ key: "userId", recordId: id, value: selectedUser.id })
    );

    dispatch(setField({ key: "user", value: selectedUser }));
    dispatch(setField({ key: "userId", value: userId }));
  };

  // Сброс пользователя
  const onRemoveUser = () => {
    dispatch(changeRecord({ key: "userId", recordId: id, value: null }));
    dispatch(setField({ key: "user", value: null }));
    dispatch(setField({ key: "userId", value: null }));
  };

  // Поиск услуги
  const onSearchService = (q: string) => {
    setServiceQuery(q);
    dispatch(findServiceByName({ query: q, organizationId }));
  };

  // Фокус в поле "Услгуи"
  const onFocusServices = (e: FocusEvent<HTMLInputElement>) => {
    dispatch(findServiceByName({ query: e.target.value, organizationId }));
  };

  // Выбор услуги
  const onSelectService = (serviceId: any) => {
    const service = searchedServices.find(
      (item: IService) => item.id === serviceId
    );

    dispatch(addService({ service, recordId: id }));
    setServiceQuery("");
  };

  // Удаление услуги
  const onRemoveService = (recordServiceId: number) =>
    dispatch(removeService(recordServiceId));

  // Изменение основных полей организации
  const onChangeRecord = (key: string, value: string | number | boolean) =>
    dispatch(changeRecord({ key, recordId: id, value }));

  // Изменение текстового поля
  const onChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => onChangeRecord(event.target.name, event.target.value);

  // Изменение статуса
  const onChangeStatus = (newStatusId: number) =>
    onChangeRecord("statusId", newStatusId);

  // Данные в виде, необходимом для формы
  const record = {
    ...data,
    services: data.services.map((item: IService) => item.id),
  };

  // Значения полей
  const fields = Object.keys(record).map((fieldName: string) => ({
    name: fieldName,
    value: fieldName === "date" ? dayjs(record[fieldName]) : record[fieldName],
  }));

  return (
    <Drawer
      title="Editing a existing case"
      placement="right"
      width={624}
      onClose={onClose}
      open={isRecordModalVisible}
    >
      <Form
        {...layout}
        name="editRecordForm"
        id="editRecordForm"
        fields={fields}
        form={form}
        onFinish={onSubmit}
        onError={onError}
      >
        <Form.Item name="date" label="Meeting date">
          <DatePicker
            value={dayjs(date)}
            name="meetDate"
            format="DD.MM.YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name="clientComment" label="Client comment">
          <TextArea
            placeholder="Client comment not specified"
            name="clientComment"
            value={clientComment}
            maxLength={255}
            autoSize={{ minRows: 3, maxRows: 3 }}
            showCount={false}
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="organizationComment" label="Your comment">
          <TextArea
            placeholder="Your comment not specified"
            name="organizationComment"
            value={organizationComment}
            maxLength={255}
            autoSize={{ minRows: 3, maxRows: 3 }}
            showCount={false}
            onChange={onChangeTextField}
          />
        </Form.Item>
        <Form.Item name="statusId" label="Status">
          <Select value={statusId} onChange={onChangeStatus}>
            {allStatuses.map((item: IStatus) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="User">
          {!!user && (
            <User
              name={user.name}
              email={user.email}
              photo={user?.avatar?.url}
              onRemove={onRemoveUser}
            />
          )}
          {!user && (
            <Select
              showSearch
              options={searchedUsers
                .filter((user: IUser) => user.id !== userId)
                .map((user: IUser) => ({
                  label: user.name,
                  value: user.id,
                }))}
              filterOption={false}
              loading={isLoadingUsers}
              value={userQuery}
              style={{ flexGrow: 1 }}
              onSearch={onSearchUser}
              onSelect={onSelectUser}
              placeholder="Select a person"
            />
          )}
        </Form.Item>
        <Form.Item label="Services">
          {!!services.length && (
            <S.Services>
              {services.map((item: IService) => (
                <Card
                  size="small"
                  key={item.id}
                  title={item.title}
                  extra={
                    <Button
                      size="small"
                      onClick={() => onRemoveService(item.id)}
                      type="link"
                    >
                      remove
                    </Button>
                  }
                  style={{ width: "100%" }}
                >
                  <p>{`${item.price} ${
                    allMeasures.find(
                      (measure: IMeasure) => measure.id === item.measureId
                    )?.title || ""
                  }`}</p>
                </Card>
              ))}
            </S.Services>
          )}
          <Select
            showSearch
            options={searchedServices
              .filter((service: IService) => !servicesIds.includes(service.id))
              .map((service: IService) => ({
                label: service.title,
                value: service.id,
              }))}
            filterOption={false}
            loading={isLoadingServices}
            value={serviceQuery}
            style={{ flexGrow: 1 }}
            onSearch={onSearchService}
            onSelect={onSelectService}
            onFocus={onFocusServices}
            placeholder="Select a person"
          />
        </Form.Item>
        <Form.Item label="Car">
          {!!car && (
            <Card
              size="small"
              title={`VIN: ${car.vin}`}
              extra={
                <Button size="small" onClick={onRemoveCar} type="link">
                  remove
                </Button>
              }
              style={{ width: "100%" }}
            >
              <p style={{ margin: 0 }}>{`${
                allBrands.find((brand: IBrand) => brand.id === car.brandId)
                  ?.title || ""
              } ${car.model}, ${car.year}`}</p>
            </Card>
          )}
          {!car && (
            <Select
              options={searchedCars.map((item: ICar) => ({
                label: `${
                  allBrands.find((brand: IBrand) => brand.id === item.brandId)
                    ?.title || ""
                } ${item.model}, ${item.year} (${item.vin})`,
                value: item.id,
              }))}
              filterOption={false}
              loading={isLoadingCars}
              style={{ flexGrow: 1 }}
              value={carId}
              onSelect={onSelectCar}
              placeholder="Select a car"
            />
          )}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditRecordModal;
