import { IInterval, IMark, IReview, IService } from "interfaces/main";

export const defaultService: IService = {
  organizationId: 1,
  title: "",
  price: 0,
  measureId: 1,
};

export const defaultReview: IReview = {
  organizationId: 1,
  text: "",
  source: "",
  userId: null,
  isRecommended: true,
};

export const defaultMark: IMark = {
  organizationId: 1,
  value: 1,
  criterionId: 1,
};

export const defaultInterval: IInterval = {
  organizationId: 1,
  timeInterval: "",
  dayId: 1,
};

export const defaultValues = {
  mark: defaultMark,
  interval: defaultInterval,
  service: defaultService,
  review: defaultReview,
};
