import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { deleteRequest, getRequest } from "api/requests";
import { createOrganization } from "features/CreateOrganizationModal/CreateOrganizationModalSlice";
import { IOrganization } from "interfaces/main";

import { RootState } from "store/store";
import { mapOrganization } from "utils/maping";
import { IOrganizationsListState } from "./interfaces";

// Selectors
export const OrganizationsListSelector = (
  state: RootState
): IOrganizationsListState => state.organizationsList;

export const OrganizationsListLoadingSelector = createSelector(
  OrganizationsListSelector,
  ({ isLoading }) => isLoading
);

export const OrganizationsListItemsSelector = createSelector(
  OrganizationsListSelector,
  ({ items }) => items
);

export const QuerySelector = createSelector(
  OrganizationsListSelector,
  ({ query }) => query
);

// Actions
export const setQuery = createAction(
  "organizationsList/setQuery",
  (query: string) => ({
    payload: query,
  })
);

// Замена организации в списке
export const exchangeOrganization = createAction(
  "organizationsList/exchangeOrganization",
  (organization: IOrganization) => ({ payload: organization })
);

export const fetchOrganizations = createAsyncThunk(
  "organizationsList/fetchOrganizations",
  async (userId: number, { rejectWithValue, getState }) => {
    const curState = getState() as RootState;
    const { user } = curState;
    const { roleId } = user;

    try {
      const response = await getRequest(
        roleId === 4 ? "organization" : `organization?userId=${userId}`,
        {},
        true
      );

      return response.data.map(mapOrganization);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление существующего тех. центра
export const removeOrganization = createAsyncThunk(
  "organizationsList/removeOrganization",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await deleteRequest(`organization/${id}`, {}, true);

      return response.data.id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IOrganizationsListState = {
  isLoading: false,
  items: [],
  query: "",
};

const organizationsListSlice = createSlice({
  name: "organizationsList",
  initialState,
  reducers: {
    setQuery: (state, { payload }) => ({
      ...state,
      query: payload.toLocaleLowerCase(),
    }),
    exchangeOrganization: (state, { payload }) => ({
      ...state,
      items: state.items.map((item: IOrganization) =>
        item.id === payload.id ? payload : item
      ),
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizations.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(fetchOrganizations.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(fetchOrganizations.fulfilled, (state, { payload }) => ({
        ...state,
        items: payload,
        isLoading: false,
      }))
      .addCase(createOrganization.fulfilled, (state, { payload }) => ({
        ...state,
        items: [payload, ...state.items],
      }))
      .addCase(removeOrganization.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(removeOrganization.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(removeOrganization.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        items: state.items.filter((item: IOrganization) => item.id !== payload),
      }));
  },
});

export default organizationsListSlice.reducer;
