import React, { useEffect } from "react";
import { Layout, Breadcrumb, Button } from "antd";
import * as S from "./Records.styled";
import { fetchRecords } from "features/RecordsList/RecordsListSlice";
import { useAppDispatch } from "hooks/useStore";
import RecordsList from "features/RecordsList";
// import { openNewRecordModal } from "features/RecordModal/RecordModalSlice";
import { AcceptedSelector, AdminSelector } from "features/User/UserSlice";
import { useSelector } from "react-redux";
import Meta from "components/Meta";

const { Content } = Layout;

const Records: React.FC = () => {
  const dispatch = useAppDispatch();

  // Подтвержденность пользователя
  const isAccepted = useSelector(AcceptedSelector);
  const isAdmin = useSelector(AdminSelector);

  useEffect(() => {
    dispatch(fetchRecords());
  }, []);

  // const onOpenNewRecordModal = () => dispatch(openNewRecordModal());

  return (
    <>
      <Meta pageName="records" />
      <Content
        style={{
          padding: "0px 20px 0px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumb
          items={[
            { title: "managment platform" },
            { title: "client requests" },
          ]}
          style={{ margin: "16px 0" }}
        />
        <S.WorkSpace>
          <S.Header>
            <Button
              type="primary"
              size="large"
              block
              // disabled={!isAccepted || !isAdmin}
              disabled
            >
              Add record
            </Button>
          </S.Header>
          <RecordsList />
        </S.WorkSpace>
      </Content>
    </>
  );
};

export default Records;
