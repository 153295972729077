import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { getRequest } from "api/requests";
import { RootState } from "store/store";
import { ILibrariesState } from "./interfaces";
import {
  IBrand,
  ICategory,
  IMeasure,
  IDay,
  IStatus,
  IUserType,
  IRole,
  ILogType,
  ICriterion,
} from "interfaces/main";

// Селекторы libraries
export const LibrariesSelector = (state: RootState): ILibrariesState =>
  state.libraries;
export const BrandsLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingBrands }) => isLoadingBrands
);
export const RolesLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingRoles }) => isLoadingRoles
);
export const CriteriaLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingCriteria }) => isLoadingCriteria
);
export const UserTypesLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingUserTypes }) => isLoadingUserTypes
);
export const LogTypesLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingLogTypes }) => isLoadingLogTypes
);
export const CategoriesLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingCategories }) => isLoadingCategories
);
export const DaysLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingDays }) => isLoadingDays
);
export const StatusesLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingStatuses }) => isLoadingStatuses
);
export const TagsLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingTags }) => isLoadingTags
);
export const MeasuresLoadingSelector = createSelector(
  LibrariesSelector,
  ({ isLoadingMeasures }) => isLoadingMeasures
);
export const BrandsSelector = createSelector(
  LibrariesSelector,
  ({ brands }) => brands
);
export const CategoriesSelector = createSelector(
  LibrariesSelector,
  ({ categories }) => categories
);
export const DaysSelector = createSelector(
  LibrariesSelector,
  ({ days }) => days
);
export const TagsSelector = createSelector(
  LibrariesSelector,
  ({ tags }) => tags
);
export const StatusSelector = createSelector(
  LibrariesSelector,
  ({ statuses }) => statuses
);
export const MeasuresSelector = createSelector(
  LibrariesSelector,
  ({ measures }) => measures
);
export const RolesSelector = createSelector(
  LibrariesSelector,
  ({ roles }) => roles
);
export const CriteriaSelector = createSelector(
  LibrariesSelector,
  ({ criteria }) => criteria
);
export const UserTypesSelector = createSelector(
  LibrariesSelector,
  ({ userTypes }) => userTypes
);
export const LogTypesSelector = createSelector(
  LibrariesSelector,
  ({ logTypes }) => logTypes
);

// Загрузка списка критериев оценки
export const fetchCriteria = createAsyncThunk(
  "libraries/fetchCriteria",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("criterion", {}, true);

      return response.data.map(({ id, title }: ICriterion) => ({
        id,
        title,
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка тегов
export const fetchTags = createAsyncThunk(
  "libraries/fetchTags",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("tag", {}, true);

      return response.data.map(({ id, title }: IBrand) => ({
        id,
        title,
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка типов пользователей
export const fetchUserTypes = createAsyncThunk(
  "libraries/fetchUserTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("userType", {}, true);

      return response.data.map(({ id, name }: IUserType) => ({
        id,
        name,
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка типов логов
export const fetchLogTypes = createAsyncThunk(
  "libraries/fetchLogTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("logType", {}, true);

      return response.data.map(({ id, name, title }: ILogType) => ({
        id,
        name,
        title,
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка ролей
export const fetchRoles = createAsyncThunk(
  "libraries/fetchRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("role", {}, true);

      return response.data.map(({ id, name }: IRole) => ({
        id,
        name,
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка бренодов
export const fetchBrands = createAsyncThunk(
  "libraries/fetchBrands",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("brand", {}, true);

      return response.data.map(({ id, title }: IBrand) => ({
        id,
        title,
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка единиц измерения
export const fetchMeasures = createAsyncThunk(
  "libraries/fetchMeasures",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("measure", {}, true);

      return response.data.map(({ id, title }: IMeasure) => ({
        id,
        title,
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка категорий
export const fetchCategories = createAsyncThunk(
  "libraries/fetchCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("category", {}, true);

      return response.data.map(
        ({
          id,
          title,
          name,
          primaryColor,
          secondaryColor,
          isVisible,
          order,
        }: ICategory) => ({
          id,
          title,
          name,
          primaryColor,
          secondaryColor,
          isVisible,
          order,
        })
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка дней недели
export const fetchDays = createAsyncThunk(
  "libraries/fetchDays",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("day", {}, true);

      return response.data.map(({ id, title, name }: IDay) => ({
        id,
        title,
        name,
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка списка статусов
export const fetchStatuses = createAsyncThunk(
  "libraries/fetchStatuses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRequest("status", {}, true);

      return response.data.map(
        ({ id, name, antColor, primaryColor, secondaryColor }: IStatus) => ({
          id,
          name,
          antColor,
          primaryColor,
          secondaryColor,
        })
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: ILibrariesState = {
  isLoadingBrands: false,
  isLoadingCategories: false,
  isLoadingDays: false,
  isLoadingStatuses: false,
  isLoadingTags: false,
  isLoadingMeasures: false,
  isLoadingCriteria: false,
  isLoadingLogTypes: false,
  isLoadingUserTypes: false,
  isLoadingRoles: false,
  brands: [],
  categories: [],
  days: [],
  statuses: [],
  measures: [],
  tags: [],
  userTypes: [],
  roles: [],
  logTypes: [],
  criteria: [],
};

const LibrariesSlice = createSlice({
  name: "libraries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCriteria.pending, (state) => ({
        ...state,
        isLoadingCriteria: true,
      }))
      .addCase(fetchCriteria.rejected, (state) => ({
        ...state,
        isLoadingCriteria: false,
      }))
      .addCase(fetchCriteria.fulfilled, (state, { payload }) => ({
        ...state,
        criteria: payload,
        isLoadingCriteria: false,
      }))
      .addCase(fetchUserTypes.pending, (state) => ({
        ...state,
        isLoadingUserTypes: true,
      }))
      .addCase(fetchUserTypes.rejected, (state) => ({
        ...state,
        isLoadingUserTypes: false,
      }))
      .addCase(fetchUserTypes.fulfilled, (state, { payload }) => ({
        ...state,
        userTypes: payload.sort((a: IUserType, b: IUserType) =>
          a.name > b.name ? 1 : -1
        ),
        isLoadingUserTypes: false,
      }))
      .addCase(fetchLogTypes.pending, (state) => ({
        ...state,
        isLoadingLogTypes: true,
      }))
      .addCase(fetchLogTypes.rejected, (state) => ({
        ...state,
        isLoadingLogTypes: false,
      }))
      .addCase(fetchLogTypes.fulfilled, (state, { payload }) => ({
        ...state,
        logTypes: payload.sort((a: ILogType, b: ILogType) =>
          a.name > b.name ? 1 : -1
        ),
        isLoadingLogTypes: false,
      }))
      .addCase(fetchRoles.pending, (state) => ({
        ...state,
        isLoadingRoles: true,
      }))
      .addCase(fetchRoles.rejected, (state) => ({
        ...state,
        isLoadingRoles: false,
      }))
      .addCase(fetchRoles.fulfilled, (state, { payload }) => ({
        ...state,
        roles: payload.sort((a: IRole, b: IRole) => (a.name > b.name ? 1 : -1)),
        isLoadingRoles: false,
      }))
      .addCase(fetchBrands.pending, (state) => ({
        ...state,
        isLoadingBrands: true,
      }))
      .addCase(fetchBrands.rejected, (state) => ({
        ...state,
        isLoadingBrands: false,
      }))
      .addCase(fetchBrands.fulfilled, (state, { payload }) => ({
        ...state,
        brands: payload.sort((a: IBrand, b: IBrand) =>
          a.title > b.title ? 1 : -1
        ),
        isLoadingBrands: false,
      }))
      .addCase(fetchCategories.pending, (state) => ({
        ...state,
        isLoadingCategories: true,
      }))
      .addCase(fetchCategories.rejected, (state) => ({
        ...state,
        isLoadingCategories: false,
      }))
      .addCase(fetchCategories.fulfilled, (state, { payload }) => ({
        ...state,
        categories: payload,
        isLoadingCategories: false,
      }))
      .addCase(fetchDays.pending, (state) => ({
        ...state,
        isLoadingDays: true,
      }))
      .addCase(fetchDays.rejected, (state) => ({
        ...state,
        isLoadingDays: false,
      }))
      .addCase(fetchDays.fulfilled, (state, { payload }) => ({
        ...state,
        days: payload,
        isLoadingDays: false,
      }))
      .addCase(fetchStatuses.pending, (state) => ({
        ...state,
        isLoadingStatuses: true,
      }))
      .addCase(fetchStatuses.rejected, (state) => ({
        ...state,
        isLoadingStatuses: false,
      }))
      .addCase(fetchStatuses.fulfilled, (state, { payload }) => ({
        ...state,
        statuses: payload,
        isLoadingStatuses: false,
      }))
      .addCase(fetchTags.pending, (state) => ({
        ...state,
        isLoadingTags: true,
      }))
      .addCase(fetchTags.rejected, (state) => ({
        ...state,
        isLoadingTags: false,
      }))
      .addCase(fetchTags.fulfilled, (state, { payload }) => ({
        ...state,
        tags: payload,
        isLoadingTags: false,
      }))
      .addCase(fetchMeasures.pending, (state) => ({
        ...state,
        isLoadingMeasures: true,
      }))
      .addCase(fetchMeasures.rejected, (state) => ({
        ...state,
        isLoadingMeasures: false,
      }))
      .addCase(fetchMeasures.fulfilled, (state, { payload }) => ({
        ...state,
        measures: payload,
        isLoadingMeasures: false,
      }));
  },
});

export default LibrariesSlice.reducer;
