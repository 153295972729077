import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { IEditOrganizationModalState } from "./interfaces";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "api/requests";
import {
  IBrand,
  ICategory,
  IInterval,
  IMark,
  IOrganization,
  IPhoto,
  IReview,
  IService,
  ITag,
} from "interfaces/main";
import { fieldName } from "consts/hardcode";

// Открытие окна с существующей организацией
export const openEditOrganizationModal = createAction(
  "editOrganizationModal/openEditOrganizationModal",
  (organizationId: number) => ({ payload: organizationId })
);

// Закрытие окна редактирования организации
export const closeModal = createAction("editOrganizationModal/closeModal");

// Загрузка всей организации по ID
export const getOrganizationById = createAsyncThunk(
  "editOrganizationModal/getOrganizationById",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await getRequest(`organization/${id}`, {}, true);
      const orgData = response.data;
      const organization: IOrganization = {
        id: orgData?.organization?.id || null,
        title: orgData?.organization?.title || "",
        description: orgData?.organization?.description || "",
        email: orgData?.organization?.email || "",
        phone: orgData?.organization?.phone || "",
        site: orgData?.organization?.site || "",
        inn: orgData?.organization?.inn || "",
        longitude: orgData?.organization?.longitude || 0,
        latitude: orgData?.organization?.latitude || 0,
        boxesCount: orgData?.organization?.boxesCount || 0,
        createdAt: orgData?.organization?.createdAt || 0,
        updatedAt: orgData?.organization?.userId || 0,
        userId: orgData?.organization?.userId || null,
        categories: orgData?.categories || [],
        intervals: orgData?.organization?.intervals || [],
        records: orgData?.organization?.records || [],
        logotype: orgData?.organization?.logotype || null,
        brands: orgData?.brands || [],
        likes: orgData?.organization?.likes || [],
        user: orgData?.organization?.user || null,
        photos: orgData?.photos || [],
        reviews: orgData?.reviews || [],
        marks: orgData?.organization?.marks || [],
        services: orgData?.services || [],
        tags: orgData?.tags || [],
      };
      return organization;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка фотографий
export const uploadPhotos = createAsyncThunk(
  "editOrganizationModal/uploadPhotos",
  async (
    { files, organizationId }: { files: File[]; organizationId: number },
    { rejectWithValue }
  ) => {
    try {
      // 👇 Create new FormData object and append files
      const data = new FormData();

      files.forEach((file, i) => {
        data.append(`file-${i}`, file, file.name);
      });

      const response = await postRequest(
        `photo/upload?organizationId=${organizationId}`,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Загрузка логотипа
export const uploadLogotype = createAsyncThunk(
  "editOrganizationModal/uploadLogotype",
  async (
    { file, organizationId }: { file: File; organizationId: number },
    { rejectWithValue }
  ) => {
    try {
      // 👇 Create new FormData object and append files
      const data = new FormData();
      data.append(`logotype`, file, file.name);

      const response = await postRequest(
        `logotype/upload?organizationId=${organizationId}`,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление фотографии
export const removePhoto = createAsyncThunk(
  "editOrganizationModal/removePhoto",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await deleteRequest(`photo/${id}`);

      return response.data.id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление логотипа
export const removeLogotype = createAsyncThunk(
  "editOrganizationModal/removeLogotype",
  async (id: number, { rejectWithValue }) => {
    try {
      return await deleteRequest(`logotype/${id}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Установка значения в поле организации
export const changeOrganization = createAsyncThunk(
  "editOrganizationModal/changeOrganization",
  async (
    {
      key,
      organizationId,
      value,
    }: {
      key: string;
      organizationId: number;
      value: string | number | boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await putRequest(
        `organization/${organizationId}`,
        {
          [key]: value,
        },
        true
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Добавление нового значения в мультиполе
export const addMultiSelect = createAsyncThunk(
  "editOrganizationModal/addMultiSelect",
  async (
    {
      objectId,
      organizationId,
      key,
    }: {
      objectId: number;
      organizationId: number;
      key: "brand" | "tag" | "category";
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const field = fieldName[key];
      const { libraries } = getState() as RootState;
      const list = libraries[field.multiple];
      const newItem = list.find(
        (item: IBrand | ICategory | ITag) => item.id === objectId
      );

      const response = await postRequest(
        `organization${field.capitalLetter}`,
        {
          organizationId,
          [field.foreignKey]: objectId,
        },
        true
      );

      return {
        fieldName: field.multiple,
        data: { ...newItem, [`organization_${field.single}`]: response.data },
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление значения в мультиполе
export const removeMultiSelect = createAsyncThunk(
  "editOrganizationModal/removeMultiSelect",
  async (
    {
      objectId,
      organizationId,
      key,
    }: {
      objectId: number;
      organizationId: number;
      key: "brand" | "tag" | "category";
    },
    { rejectWithValue }
  ) => {
    try {
      const field = fieldName[key];

      const response = await deleteRequest(
        `organization${field.capitalLetter}`,
        {
          organizationId,
          [field.foreignKey]: objectId,
        },
        true
      );

      return { fieldName: field.multiple, id: response.data.id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Добавление элемента массива
export const addArrayItem = createAsyncThunk(
  "editOrganizationModal/addArrayItem",
  async (
    {
      arrayName,
      item,
    }: { arrayName: string; item: IService | IReview | IInterval | IMark },
    { rejectWithValue }
  ) => {
    try {
      const response = await postRequest(arrayName, item, true);

      return { arrayName, data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Изменение элемента массива
export const changeArrayItem = createAsyncThunk(
  "editOrganizationModal/changeArrayItem",
  async (
    {
      arrayName,
      key,
      itemId,
      value,
    }: {
      arrayName: "service" | "interval" | "review" | "mark";
      key: string;
      itemId: number;
      value: string | number | boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await putRequest(
        `${arrayName}/${itemId}`,
        {
          [key]: value,
        },
        true
      );

      return { arrayName, data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление услуги
export const removeArrayItem = createAsyncThunk(
  "editOrganizationModal/removeArrayItem",
  async (
    {
      arrayName,
      itemId,
    }: {
      arrayName: "service" | "interval" | "review" | "mark" | "like";
      itemId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await deleteRequest(`${arrayName}/${itemId}`, {}, true);

      return { arrayName, data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Добавление лайка
export const addLike = createAsyncThunk(
  "editOrganizationModal/addLike",
  async (
    {
      userId,
      organizationId,
    }: {
      userId: number;
      organizationId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await postRequest(
        "like",
        { userId, organizationId },
        true
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialOrganization: IOrganization = {
  id: null,
  title: "",
  description: "",
  phone: "",
  site: "",
  email: "",
  categories: [],
  inn: "",
  boxesCount: 0,
  longitude: 0,
  latitude: 0,
  photos: [],
  userId: null,
  reviews: [],
  createdAt: "",
  updatedAt: "",
  tags: [],
  services: [],
  marks: [],
  intervals: [],
  brands: [],
  logotype: null,
  likes: [],
};

const initialState: IEditOrganizationModalState = {
  data: initialOrganization,
  isLoading: false,
  isPhotosLoading: false,
  isLogotypeLoading: false,
  isVisible: false,
};

const editOrganizationModalSlice = createSlice({
  name: "editOrganizationModal",
  initialState,
  reducers: {
    closeModal: () => initialState,
    openEditOrganizationModal: (_, { payload }) => ({
      ...initialState,
      isVisible: true,
      data: {
        ...initialOrganization,
        id: payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationById.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getOrganizationById.rejected, (state) => ({
        ...state,
        isLoading: false,
      }))
      .addCase(getOrganizationById.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ...payload,
        },
      }))
      .addCase(changeOrganization.fulfilled, (state, { payload }) => ({
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      }))
      .addCase(addMultiSelect.fulfilled, (state, { payload }) => ({
        ...state,
        data: {
          ...state.data,
          [payload.fieldName]: [
            ...state.data?.[payload.fieldName],
            payload.data,
          ],
        },
      }))
      .addCase(addArrayItem.fulfilled, (state, { payload }) => {
        const field = fieldName[payload.arrayName].multiple;
        return {
          ...state,
          data: {
            ...state.data,
            [field]: [...state.data?.[field], payload.data],
          },
        };
      })
      .addCase(changeArrayItem.fulfilled, (state, { payload }) => {
        const field = fieldName[payload.arrayName].multiple;
        return {
          ...state,
          data: {
            ...state.data,
            [field]: state?.data?.[field].map(
              (item: IService | IInterval | IMark | IReview) =>
                item.id === payload.data.id
                  ? { ...item, ...payload.data }
                  : item
            ),
          },
        };
      })
      .addCase(removeArrayItem.fulfilled, (state, { payload }) => {
        const field = fieldName[payload.arrayName].multiple;
        return {
          ...state,
          data: {
            ...state.data,
            [field]: state.data?.[field].filter(
              (item: IService | IInterval | IMark | IReview) =>
                item.id !== payload.data.id
            ),
          },
        };
      })
      .addCase(removeMultiSelect.fulfilled, (state, { payload }) => ({
        ...state,
        data: {
          ...state.data,
          [payload.fieldName]: state.data?.[payload.fieldName].filter(
            (item: IBrand | ICategory | ITag) => item.id !== payload.id
          ),
        },
      }))
      .addCase(addLike.fulfilled, (state, { payload }) => ({
        ...state,
        data: {
          ...state.data,
          likes: [...state.data.likes, payload],
        },
      }))
      .addCase(uploadPhotos.pending, (state) => ({
        ...state,
        isPhotosLoading: true,
      }))
      .addCase(uploadPhotos.rejected, (state) => ({
        ...state,
        isPhotosLoading: false,
      }))
      .addCase(uploadPhotos.fulfilled, (state, { payload }) => ({
        ...state,
        isPhotosLoading: false,
        data: {
          ...state.data,
          photos: [...state.data?.photos, ...payload],
        },
      }))
      .addCase(uploadLogotype.pending, (state) => ({
        ...state,
        isLogotypeLoading: true,
      }))
      .addCase(uploadLogotype.rejected, (state) => ({
        ...state,
        isLogotypeLoading: false,
      }))
      .addCase(uploadLogotype.fulfilled, (state, { payload }) => ({
        ...state,
        isLogotypeLoading: false,
        data: {
          ...state.data,
          logotype: payload,
        },
      }))
      .addCase(removePhoto.pending, (state) => ({
        ...state,
        isPhotosLoading: true,
      }))
      .addCase(removePhoto.rejected, (state) => ({
        ...state,
        isPhotosLoading: false,
      }))
      .addCase(removePhoto.fulfilled, (state, { payload }) => ({
        ...state,
        isPhotosLoading: false,
        data: {
          ...state.data,
          photos: state.data.photos.filter(
            (item: IPhoto) => item.id !== payload
          ),
        },
      }))
      .addCase(removeLogotype.pending, (state) => ({
        ...state,
        isLogotypeLoading: true,
      }))
      .addCase(removeLogotype.rejected, (state) => ({
        ...state,
        isLogotypeLoading: false,
      }))
      .addCase(removeLogotype.fulfilled, (state, { payload }) => ({
        ...state,
        isLogotypeLoading: false,
        data: {
          ...state.data,
          logotype: null,
        },
      }));
  },
});

// Селекторы
export const EditOrganizationModalSelector = (
  state: RootState
): IEditOrganizationModalState => state.editOrganizationModal;

export const EditOrganizationModalLoadingSelector = createSelector(
  EditOrganizationModalSelector,
  ({ isLoading }) => isLoading
);

export const EditOrganizationModalPhotosLoadingSelector = createSelector(
  EditOrganizationModalSelector,
  ({ isPhotosLoading }) => isPhotosLoading
);

export const EditOrganizationModalLogotypeLoadingSelector = createSelector(
  EditOrganizationModalSelector,
  ({ isLogotypeLoading }) => isLogotypeLoading
);

export const EditOrganizationModalVisibleSelector = createSelector(
  EditOrganizationModalSelector,
  ({ isVisible }) => isVisible
);

export const EditOrganizationModalDataSelector = createSelector(
  EditOrganizationModalSelector,
  ({ data }) => data
);

export default editOrganizationModalSlice.reducer;
