export const metaData = {
  login: {
    title: "CARCITY | Login",
    description: "",
    keywords: "",
  },
  signUp: {
    title: "CARCITY | Registration",
    description: "",
    keywords: "",
  },
  messages: {
    title: "CARCITY | Messages from landing",
    description: "",
    keywords: "",
  },
  myServices: {
    title: "CARCITY | My organizations",
    description: "",
    keywords: "",
  },
  records: {
    title: "CARCITY | Client requests",
    description: "",
    keywords: "",
  },
  services: {
    title: "CARCITY | All organizations",
    description: "",
    keywords: "",
  },
  users: {
    title: "CARCITY | Users",
    description: "",
    keywords: "",
  },
  logs: {
    title: "CARCITY | Logs",
    description: "",
    keywords: "",
  },
};
