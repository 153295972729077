import React from "react";
import * as S from "./OrganizationsList.styled";
import { useSelector } from "react-redux";
import {
  OrganizationsListItemsSelector,
  removeOrganization,
} from "./OrganizationsListSlice";
import Table from "./components/Table";
import { useAppDispatch } from "hooks/useStore";
import { openEditOrganizationModal } from "features/EditOrganizationModal/EditOrganizationModalSlice";
import { AcceptedSelector } from "features/User/UserSlice";

const Organizations: React.FC = () => {
  const dispatch = useAppDispatch();

  // Список загруженных сервисных. центров
  const services = useSelector(OrganizationsListItemsSelector);

  // Подтвержденность пользователя
  const isAccepted = useSelector(AcceptedSelector);

  // Открытие модалки с карточкой сервис. сентра для редактирования
  const onOpenOldOrganizationModal = (organizationId: number) =>
    dispatch(openEditOrganizationModal(organizationId));

  // Открытие модалки с карточкой сервис. сентра для редактирования
  const onRemoveOrganization = (organizationId: number) =>
    dispatch(removeOrganization(organizationId));

  return (
    <S.Wrap>
      <Table
        items={services}
        isDisabled={!isAccepted}
        onEdit={onOpenOldOrganizationModal}
        onRemove={onRemoveOrganization}
      />
    </S.Wrap>
  );
};

export default Organizations;
